import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  deals: []
}

export const dealSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    pushDeals: (state, action) => {
      state.deals = action.payload
    },
  },
})

export const { pushDeals } = dealSlice.actions
export default dealSlice.reducer