import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  products: []
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    pushProducts: (state, action) => {
      state.products = action.payload
    },
  },
})

export const { pushProducts } = cartSlice.actions
export default cartSlice.reducer