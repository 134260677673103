import React from "react"
import { usePagination, dots } from "../hooks/usePagination"

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (currentPage === 0 || paginationRange.length < 2)
    return null

  let lastPage = paginationRange[paginationRange.length - 1]

  return (
    <ul className="pagination pagination-sm">
      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`} key="first">
        <a className="page-link" onClick={() => onPageChange(1 , 0)}>First</a>
      </li>
      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`} key="previous">
        <a className="page-link" onClick={() => onPageChange(currentPage - 1, (currentPage - 2) * pageSize)}>Previous</a>
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === dots)
          return <li className="page-item dots" key={pageNumber}>
            <a className="page-link">&#8230;</a>
          </li>

        return (
          <li className={`page-item ${pageNumber === currentPage ? "active" : ""}`} key={pageNumber}>
            <a className="page-link" onClick={() => onPageChange(pageNumber, (pageNumber - 1) * pageSize)}>{pageNumber}</a>
          </li>
        )
      })}
      <li className={`page-item ${currentPage === lastPage ? "disabled" : ""}`} key="next">
        <a className="page-link" onClick={() => onPageChange(currentPage + 1, (currentPage) * pageSize)}>Next</a>
      </li>
      <li className={`page-item ${currentPage === lastPage ? "disabled" : ""}`} key="last">
        <a className="page-link" onClick={() => onPageChange(Math.ceil(totalCount / pageSize), Math.ceil(totalCount / pageSize - 1) * pageSize)}>Last</a>
      </li>
    </ul>
  )
}

export default Pagination