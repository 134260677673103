import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createIncomeStatement = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/income_statement`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const updateIncomeStatement = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/income_statement`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteIncomeStatement = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/income_statement/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteIncomeStatements = async (token) => {
  const { data: response } = await axios.delete(`${apiUrl}/income_statements`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getIncomeStatements = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/income_statements?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getIncomeStatement = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/income_statement?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}