import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as orderApis from "../../utilities/apis/order"
import { getProcessedStatus, getOrderStatus, getPaymentStatus, isDeal } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"

const TrashOrders = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const formRef = useRef(null)
  const [orders, setOrders] = useState([])
  const [count, setCount] = useState(0)
  const [selected, setSelected] = useState([])
  const [active, setActive] = useState(1)
  const [filters, setFilters] = useState({
    trash: true,
    offset: 0,
    limit: 10,
  })

  const handleView = (id) => {
    navigate(`/dashboard/order?id=${id}`)
  }

  const handleSearch = () => {
    const where = {}

    if (formRef.current.from_date.value && formRef.current.from_time.value && formRef.current.to_date.value && formRef.current.to_time.value) {
      where.from_order_datetime = moment(`${formRef.current.from_date.value} ${formRef.current.from_time.value}`).format("YYYY-MM-DD HH:mm")
      where.to_order_datetime = moment(`${formRef.current.to_date.value} ${formRef.current.to_time.value}`).format("YYYY-MM-DD HH:mm")
    } else {
      if (formRef.current.from_date.value && formRef.current.to_date.value) {
        where.from_order_date = moment(formRef.current.from_date.value).format("YYYY-MM-DD")
        where.to_order_date = moment(formRef.current.to_date.value).format("YYYY-MM-DD")
      }
    }

    setFilters({ ...filters, ...where })
  }

  const handleReset = () => {
    setFilters({ limit: 10, offset: 0 })
  }

  const handleRestore = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to restore?")) return

      const response = await orderApis.restoreOrder(token, id)
      if (!response.status) throw new Error(response.message)

      setOrders((previous) => {
        const orders = previous.filter(x => x.id !== id)
        return [...orders]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const handleAll = (e) => {
    if (e.target.checked)
      setSelected(orders.map(order => order.id))
    else
      setSelected([])
  }

  const handleChange = (id) => {
    if (!selected.includes(id))
      setSelected((previous) => {
        previous.push(id)
        return [...previous]
      })
    else
      setSelected((previous) => {
        return previous.filter(x => x !== id)
      })
  }

  const handlePageChange = (page, offset) => {
    setFilters({ ...filters, offset })
    setActive(page)
  }

  const getOrders = async () => {
    try {
      const query = new URLSearchParams(filters)
      const response = await orderApis.getOrders(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrders(response.orders)
      setCount(response.count)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getOrders()
  }, [])

  useEffect(() => {
    getOrders(filters)
  }, [filters])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="orders" className="my-2">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="text-danger fw-bold m-0">Trash Orders</h3>
          </div>

          <div className="row align-items-center mb-2">
            <div className="col-md-3 pe-1">
              <div className="d-flex align-items-center">
                <label className="col-form-label">From</label>
                <div className="input-group input-group-sm ms-1">
                  <input type="date" className="form-control bg-light" name="from_date" />
                  <input type="time" className="form-control bg-light" name="from_time" />
                </div>
              </div>
            </div>
            <div className="col-md-3 px-1">
              <div className="d-flex align-items-center">
                <label className="col-form-label">To</label>
                <div className="input-group input-group-sm ms-1">
                  <input type="date" className="form-control bg-light" name="to_date" />
                  <input type="time" className="form-control bg-light" name="to_time" />
                </div>
              </div>
            </div>
            <div className="col-md-6 ps-1 text-end">
              <button type="button" className="btn btn-sm btn-light text-primary" onClick={handleReset} title="Refresh Orders"><i className="fas fa-sync-alt"></i></button>
              <div className="btn-group btn-group-sm ms-2" role="group">
                <button type="button" className="btn btn-sm btn-light text-primary" onClick={handleSearch}><i className="fas fa-search"></i></button>
                <button type="reset" className="btn btn-sm btn-light text-danger" onClick={handleReset}><i className="fas fa-times"></i></button>
              </div>
            </div>
          </div>
        </div>

        <table className="table table-bordered table-hover shadow-none text-dark">
          <thead className="table-light">
            <tr>
              <th scope="col" className="align-middle text-center">
                <input className="form-check-input" type="checkbox" id="all" value="all" onChange={handleAll} />
                <span className="ms-1">({selected.length})</span>
              </th>
              <th scope="col" style={{ width: "7%" }} className="align-middle">Order #</th>
              <th scope="col" style={{ width: "8%" }} className="align-middle">Date</th>
              <th scope="col" style={{ width: "15%" }} className="align-middle">Customer</th>
              <th scope="col" style={{ width: "7%" }} className="align-middle">Driver</th>
              <th scope="col" style={{ width: "23%" }} className="align-middle">Items</th>
              <th scope="col" style={{ width: "7%" }} className="align-middle text-center">Discount</th>
              <th scope="col" style={{ width: "7%" }} className="align-middle text-center">Total</th>
              <th scope="col" style={{ width: "15%" }} className="align-middle text-center">Tags</th>
              <th scope="col" style={{ width: "7%" }} className="align-middle text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((row) => {
              return (
                <tr key={row.id.toString()} onDoubleClick={() => handleView(row.id)}>
                  <td className="align-middle text-center">
                    <input className="form-check-input" type="checkbox" id={row.id} value={row.id} onChange={() => handleChange(row.id)} checked={selected.includes(row.id)} />
                  </td>
                  <td className="align-middle">P{row.id}</td>
                  <td className="align-middle">{moment(row.order_datetime).format("DD-MM-YYYY")}</td>
                  <td className="align-middle">{row.customer.mobile} {row.customer.name ? ` | ${row.customer.name}` : ''}<br /><span className="small"><i className="text-danger bi bi-geo-alt me-1"></i>{row.customer.address}</span></td>
                  <td className="align-middle">{row.driver?.name}</td>
                  <td className="align-middle small">{row.order_lineitems.map((item) => {
                    if (isDeal(item.type))
                      return <div key={`deal-${item.id.toString()}`}>{item.title} x{item.quantity} = PKR {(item.quantity * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}</div>
                    else
                      return <div key={`product-${item.id.toString()}`}>{item.title} x{item.quantity} = PKR {(item.quantity * item.price).toFixed(2)}</div>
                  })}</td>
                  <td className="align-middle text-center">Rs. {row.discount.toFixed(2)}</td>
                  <td className="align-middle text-center">Rs. {(row.total - row.discount).toFixed(2)}</td>
                  <td className="align-middle text-center">{getProcessedStatus[row.processed]} {getOrderStatus[row.order_status]} {getPaymentStatus[row.payment_status]}</td>
                  <td className="align-middle text-center">
                    {(permissions["RESTORE_ORDER"]) && (<a className="text-success me-2" title="Restore Order" onClick={() => handleRestore(row.id)}><i className="far fa-trash-alt"></i></a>)}
                    {(permissions["READ_ORDER"]) && (<a className="me-2" onClick={() => handleView(row.id)}><i className="far fa-clipboard"></i></a>)}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        <div className="container-fluid">
          <nav aria-label="pagination">
            <Pagination
              currentPage={active}
              totalCount={count}
              pageSize={filters.limit}
              offset={filters.offset}
              siblingCount={3}
              onPageChange={handlePageChange}
            />
          </nav>
        </div>
      </main>
    </Layout>
  )
}

export default TrashOrders
