import Layout from "../../components/Layout"
import DriverBy from "../../components/DriverBy"

const ReportDrivers = () => {
  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-2 text-dark">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <DriverBy />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default ReportDrivers
