import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import moment from "moment"
import * as productSlice from "../store/product"
import * as campaignApis from "../utilities/apis/campaign"
import * as customerApis from "../utilities/apis/customer"
import * as zoneApis from "../utilities/apis/zone"
import * as productApis from "../utilities/apis/product"
import Layout from "../components/Layout"
import ProductFilterModal from "../components/ProductFilterModal"

const CreateCampaign = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user, token } = useSelector(state => state.user)
  const productState = useSelector(state => state.product)
  const filterRef = useRef(null)
  const [zones, setZones] = useState([])
  const [customers, setCustomers] = useState([])
  const [count, setCount] = useState(0)
  const [spent, setSpent] = useState(0)
  const [selected, setSelected] = useState([])
  const [selectedProducts, setSelectedProducts] = useState(() => {
    const products = {}
    for (let i = 0; i < productState.products.length; i++) {
      products[productState.products[i].id] = {
        checked: false,
        title: productState.products[i].title,
        quantity: 1,
      }
    }
    return products
  })

  const handleAll = (e) => {
    if (e.target.checked)
      setSelected(customers.map(customer => customer.id))
    else
      setSelected([])
  }

  const handleChange = (id) => {
    if (!selected.includes(id))
      setSelected((previous) => {
        previous.push(id)
        return [...previous]
      })
    else
      setSelected((previous) => {
        return previous.filter(x => x !== id)
      })
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const name = e.target.name.value
      const message = e.target.message.value
      const campaign = { name, message }
      const campaignLineitems = selected.map((x) => ({ customer_id: x }))
      if (!campaignLineitems.length) throw new Error("Select atleast one customer")

      const response = await campaignApis.createCampaign(token, {
        campaign,
        campaign_lineitems: campaignLineitems
      })
      if (!response.status) throw new Error(response.message)

      e.target.name.value = ""
      e.target.message.value = ""
      setSelected([])
      setCustomers([])

      alert("Campaign saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const searchCustomers = async () => {
    try {
      const form = filterRef.current
      const params = {}

      const zones = []
      if (!form.zone.length && form.zone.value)
        zones.push(form.zone.value)
      for (let i = 0; i < form.zone.length; i++)
        zones.push(form.zone[i].value)
      const tags = []
      if (!form.tags.length && form.tags.value)
        tags.push(form.tags.value)
      for (let i = 0; i < form.tags.length; i++)
        tags.push(form.tags[i].value)
      if (form.from_order_date.value) params.from_order_date = form.from_order_date.value
      if (form.to_order_date.value) params.to_order_date = form.to_order_date.value
      if (form.from_campaign_date.value) params.from_campaign_date = form.from_campaign_date.value
      if (form.to_campaign_date.value) params.to_campaign_date = form.to_campaign_date.value
      if (zones.length) params.zone_id = zones.join(",")
      if (form.min_count.value) params.min_count = form.min_count.value
      if (form.max_count.value) params.max_count = form.max_count.value
      if (form.min_spent.value) params.min_spent = form.min_spent.value
      if (form.max_spent.value) params.max_spent = form.max_spent.value
      if (!(form.w_address.checked && form.wo_address.checked)) {
        if (form.w_address.checked) params.is_address = form.w_address.value
        if (form.wo_address.checked) params.is_address = form.wo_address.value
      }
      const products = []
      for (const key in selectedProducts)
        if (selectedProducts[key].checked)
          products.push({ product_id: parseInt(key), quantity: selectedProducts[key].quantity })

      const response = await customerApis.searchCustomers(token, {
        customer: params,
        products
      })
      if (!response.status) throw new Error(response.message)

      setCustomers(response.customers)
    } catch (error) {
      alert(error.message)
    }
  }

  const getZones = async () => {
    try {
      const response = await zoneApis.getZones(token, "")
      if (!response.status) throw new Error(response.message)

      setZones(response.zones.map((zone) => ({
        value: zone.id.toString(),
        label: zone.name
      })))
    } catch (error) {
      alert(error.message)
    }
  }

  const getProducts = async () => {
    try {
      const query = new URLSearchParams()
      const response = await productApis.getProducts(token, query.toString())
      if (!response.status) throw new Error(response.message)

      dispatch(productSlice.pushProducts(response.products.map((product) => ({
        ...product,
        type: "product",
        cart: 0
      }))))
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getZones()
    getProducts()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <ProductFilterModal products={selectedProducts} setProducts={setSelectedProducts} />

      <main id="campaign" className="my-2">
        <form method="POST" onSubmit={handleSubmit}>
          <div className="container-fluid border-bottom">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h3 className="fw-bold m-0">Campaign</h3>
              <button type="submit" className="btn btn-primary">Save</button>
            </div>

            <div className="row align-items-start mb-2">
              <div className="col-md-3 pe-1">
                <label htmlFor="name" className="form-label fw-bold text-muted mb-0 me-2">Name</label>
                <input type="text" className="form-control" name="name" placeholder="Name" />
              </div>
              <div className="col-md-9 ps-1">
                <label htmlFor="message" className="form-label fw-bold text-muted mb-0 me-2">Message</label>
                <textarea className="form-control" name="message" rows="4" placeholder="Hi {{name}}"></textarea>
              </div>
            </div>
          </div>
        </form>

        <form ref={filterRef}>
          <div className="container-fluid my-2">
            <div className="row align-items-end mb-2">
              <div className="col-md-3 pe-1">
                <label htmlFor="last-order" className="form-label fw-bold text-muted mb-0 me-2">Last Order</label>
                <div className="input-group">
                  <input type="date" className="form-control" name="from_order_date" defaultValue={moment().subtract(7, "days").format("YYYY-MM-DD")} />
                  <input type="date" className="form-control" name="to_order_date" defaultValue={moment().format("YYYY-MM-DD")} />
                </div>
              </div>
              <div className="col-md-3 px-1">
                <label htmlFor="last-campaign" className="form-label fw-bold text-muted mb-0 me-2">Last Campaign</label>
                <div className="input-group">
                  <input type="date" className="form-control" name="from_campaign_date" />
                  <input type="date" className="form-control" name="to_campaign_date" />
                </div>
              </div>
              <div className="col-md-3 px-1">
                <label htmlFor="zone" className="form-label fw-bold text-muted mb-0 me-2">Zone</label>
                <Select
                  options={zones}
                  name="zone"
                  isMulti
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "0.375rem",
                      borderColor: state.isFocused ? "#9aacf3" : "#ced4da",
                      boxShadow: !state.isFocused ? "0 1px 2px rgba(0, 0, 0, 0.05)" : "0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 0.25rem rgba(52, 89, 230, 0.25)",
                      transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    }),
                  }}
                />
              </div>
              <div className="col-md-3 ps-1">
                <label htmlFor="tags" className="form-label fw-bold text-muted mb-0 me-2">Tags</label>
                <Select
                  name="tags"
                  isMulti
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderRadius: "0.375rem",
                      borderColor: state.isFocused ? "#9aacf3" : "#ced4da",
                      boxShadow: !state.isFocused ? "0 1px 2px rgba(0, 0, 0, 0.05)" : "0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 0.25rem rgba(52, 89, 230, 0.25)",
                      transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                    }),
                  }}
                />
              </div>
            </div>
            <div className="row align-items-end">
              <div className="col-md-3 pe-1">
                <label className="form-label fw-bold text-muted mb-0 me-2">Order Count</label>
                <div className="input-group input-group-sm">
                  <input type="number" className="form-control" name="min_count" placeholder="Min" />
                  <input type="number" className="form-control" name="max_count" placeholder="Max" />
                </div>
              </div>
              <div className="col-md-3 px-1">
                <label className="form-label fw-bold text-muted mb-0 me-2">Order Spent</label>
                <div className="input-group input-group-sm">
                  <input type="number" step="2" className="form-control" name="min_spent" placeholder="Min" />
                  <input type="number" step="2" className="form-control" name="max_spent" placeholder="Max" />
                </div>
              </div>
              <div className="col-md-3 px-1">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="checkbox" name="w_address" id="w-address" value="not" defaultChecked />
                  <label className="form-check-label" htmlFor="w-address">With Address</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="checkbox" name="wo_address" id="wo-address" value="null" defaultChecked />
                  <label className="form-check-label" htmlFor="wo-address">Without Address</label>
                </div>
              </div>
              <div className="col-md-3 ps-1 text-end">
                <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#productFilterModal">Product Filter</button>
                <button type="button" className="btn btn-primary me-2" onClick={searchCustomers}>Filter</button>
                <button type="button" className="btn btn-danger">Clear</button>
              </div>
            </div>
          </div>
        </form>

        <table className="table table-sm table-bordered table-hover shadow-none text-dark">
          <thead className="table-light">
            <tr>
              <th scope="col" className="text-center">
                <input className="form-check-input" type="checkbox" id="all" value="all" onChange={handleAll} />
              </th>
              <th scope="col" style={{ width: "8%" }}>Name</th>
              <th scope="col" style={{ width: "8%" }}>Mobile #</th>
              <th scope="col" style={{ width: "24%" }}>Address</th>
              <th scope="col" style={{ width: "12%" }}>Last Order</th>
              <th scope="col" style={{ width: "12%" }}>Last Campaign</th>
              <th scope="col" style={{ width: "8%" }}>Tags</th>
              <th scope="col" style={{ width: "8%" }}>Zone</th>
              <th scope="col" style={{ width: "8%" }}>Orders</th>
              <th scope="col" style={{ width: "8%" }}>Spent</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((row) => {
              return (
                <tr key={row.id.toString()}>
                  <td className="align-middle text-center">
                    <input className="form-check-input" type="checkbox" id={row.id} value={row.id} onChange={() => handleChange(row.id)} checked={selected.includes(row.id)} />
                  </td>
                  <td className="align-middle">{row.name || "Customer"}</td>
                  <td className="align-middle">{row.mobile}</td>
                  <td className="align-middle small">{row.address}</td>
                  <td className="align-middle">{row.last_order && moment(row.last_order).format("DD-MM-YYYY")}</td>
                  <td className="align-middle">{row.last_campaign && moment(row.last_campaign).format("DD-MM-YYYY")}</td>
                  <td className="align-middle">{row.tags}</td>
                  <td className="align-middle">{row.zone.name}</td>
                  <td className="align-middle">{row.count}</td>
                  <td className="align-middle">{row.spent}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </main>
    </Layout>
  )
}

export default CreateCampaign
