import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as campaignApis from "../../utilities/apis/campaign"
import Layout from "../../components/Layout"

const Campaigns = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const [campaigns, setCampaigns] = useState([])
  const [rows, setRows] = useState([])
  const [active, setActive] = useState(1)
  const limit = 10

  const handleNew = () => {
    navigate("/dashboard/campaign")
  }

  const handleView = (id) => {
    navigate(`/dashboard/campaign?id=${id}`)
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await campaignApis.deleteCampaign(token, id)
      if (!response.status) throw new Error(response.message)

      setCampaigns((previous) => {
        const campaigns = previous.filter(x => x.id !== id)
        return [...campaigns]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const getCampaigns = async () => {
    try {
      const response = await campaignApis.getCampaigns(token, "")
      if (!response.status) throw new Error(response.message)

      setCampaigns(response.campaigns)
      setRows(response.campaigns.slice(0, limit))
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getCampaigns()
  }, [])

  useEffect(() => {
    setRows(campaigns.slice((active - 1) * limit, active * limit))
  }, [campaigns.length])

  useEffect(() => {
    setRows(campaigns.slice((active - 1) * limit, active * limit))
  }, [active])

  const pages = new Array(Math.ceil(campaigns.length / limit)).fill(null).map((_, index) => index + 1)

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="campaigns" className="my-2">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="fw-bold m-0">Campaigns</h3>
            {(permissions["CREATE_CAMPAIGN"]) && (
              <button className="btn btn-primary" onClick={() => handleNew()}>
                New
              </button>
            )}
          </div>
        </div>
        <table className="table table-bordered shadow-none">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "20%" }}>Name</th>
              <th scope="col" style={{ width: "50%" }}>Message</th>
              <th scope="col" style={{ width: "20%" }}>Progress</th>
              <th scope="col" style={{ width: "10%" }} className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              const total = row.campaign_lineitems.length
              const sent = row.campaign_lineitems.filter(item => item.sent).length / total * 100
              const responded = row.campaign_lineitems.filter(item => item.responded).length / total * 100

              return (
                <>
                  <tr key={row.id.toString()}>
                    <td className="align-middle">{row.name}</td>
                    <td className="align-middle">{row.message}</td>
                    <td className="align-middle">
                      <div className="progress" style={{ height: "8px" }}>
                        <div className="progress-bar bg-success" role="progressbar" style={{ width: `${responded}%` }} aria-valuenow={(responded).toString()} aria-valuemin="0" aria-valuemax="100"></div>
                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${sent - responded}%` }} aria-valuenow={(sent).toString()} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td>
                    <td className="align-middle text-center">
                    {(permissions["UPDATE_CAMPAIGN"]) && (<a className="me-2" onClick={() => handleView(row.id)}>
                        <i className="far fa-clipboard"></i>
                      </a>
                    )}
                      {(permissions["DELETE_CAMPAIGN"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}>
                        <i className="far fa-trash-alt"></i>
                      </a>
                      )}
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
        </table>

        <div className="container-fluid">
          <nav aria-label="pagination">
            <ul className="pagination pagination-sm">
              <li className={`page-item ${active === 1 ? "disabled" : ""}`}>
                <a className="page-link" href="#" onClick={() => setActive((previous) => previous -= 1)}>Previous</a>
              </li>
              {pages.map((page) => (
                <li key={page} className={`page-item ${page === active ? "active" : ""}`}><a className="page-link" href="#" onClick={() => setActive(page)}>{page}</a></li>
              ))}
              <li className={`page-item ${active === Math.ceil(campaigns.length / limit) ? "disabled" : ""}`}>
                <a className="page-link" href="#" onClick={() => setActive((previous) => previous += 1)}>Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </main>
    </Layout>
  )
}

export default Campaigns
