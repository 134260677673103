import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as driverApis from "../../utilities/apis/driver"
import Layout from "../../components/Layout"

const Driver = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const nameRef = useRef(null)
  const mobileRef = useRef(null)
  const usernameRef = useRef(null)
  const passwordRef = useRef(null)
  const commissionRef = useRef(null)
  const params = new URLSearchParams(window.location.search)
  const [active, setActive] = useState(true)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const name = e.target.name.value
      const mobile = e.target.mobile.value
      const username = e.target.username.value
      const password = e.target.password.value
      const commission = e.target.commission.value
      const payload = { active, name, mobile, username, password, commission }

      if (!params.has("id")) {
        const response = await driverApis.createDriver(token, payload)
        if (!response.status) throw new Error(response.message)

        nameRef.current.value = ""
        mobileRef.current.value = ""
        usernameRef.current.value = ""
        passwordRef.current.value = ""
        commissionRef.current.value = ""
      } else {
        payload.id = parseInt(params.get("id"))
        const response = await driverApis.updateDriver(token, payload)
        if (!response.status) throw new Error(response.message)
      }

      alert("Rider saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const getDriver = async () => {
    try {
      const response = await driverApis.getDriver(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setActive(response.driver.active)
      nameRef.current.value = response.driver.name
      mobileRef.current.value = response.driver.mobile
      usernameRef.current.value = response.driver.username
      passwordRef.current.value = response.driver.password
      commissionRef.current.value = response.driver.commission
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    if (params.has("id")) getDriver()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="driver" className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <div className="card">
                <div className="card-header bg-white border-bottom-0 text-center">
                  <h3 className="fw-bold m-0">Rider</h3>
                </div>
                <div className="card-body">
                  <form method="POST" onSubmit={handleSubmit}>
                    <div className="form-check mb-3">
                      <input className="form-check-input" type="checkbox" id="active" checked={active} onChange={() => setActive(!active)} />
                      <label className="form-check-label" htmlFor="active">Active</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={nameRef} type="text" className="form-control" id="name" placeholder="Name" required />
                      <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile #" required />
                      <label htmlFor="mobile">Mobile #</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={usernameRef} type="text" className="form-control" id="username" placeholder="Username" required />
                      <label htmlFor="username">Username</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={passwordRef} type="password" className="form-control" id="password" placeholder="Password" required />
                      <label htmlFor="password">Password</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={commissionRef} type="number" className="form-control" id="commission" placeholder="Commission" required />
                      <label htmlFor="commission">Commission</label>
                    </div>

                    <button type="submit" className="btn btn-primary w-100 py-3">Save</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Driver
