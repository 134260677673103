import Layout from "../../components/Layout"
import Product from "../../components/Products"
import ProductBy from "../../components/ProductBy"

const ReportProducts = () => {
  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="products" className="my-2 text-dark">
        <div className="container-fluid">
          <Product />
          <ProductBy />
        </div>
      </main>
    </Layout>
  )
}

export default ReportProducts
