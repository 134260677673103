import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as dealApis from "../../utilities/apis/deal"
import Layout from "../../components/Layout"

const Deals = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const [deals, setDeals] = useState([])
  const [rows, setRows] = useState([])
  const [active, setActive] = useState(1)
  const limit = 10

  const handleNew = () => {
    navigate("/dashboard/deal")
  }

  const handleEdit = (id) => {
    navigate(`/dashboard/deal?id=${id}`)
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await dealApis.deleteDeal(token, id)
      if (!response.status) throw new Error(response.message)

      setDeals((previous) => {
        const deals = previous.filter(x => x.id !== id)
        return [...deals]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const getDeals = async () => {
    try {
      const response = await dealApis.getDeals(token, "")
      if (!response.status) throw new Error(response.message)

      setDeals(response.deals)
      setRows(response.deals.slice(0, limit))
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getDeals()
  }, [])

  useEffect(() => {
    setRows(deals.slice((active - 1) * limit, active * limit))
  }, [deals.length])

  useEffect(() => {
    setRows(deals.slice((active - 1) * limit, active * limit))
  }, [active])

  const pages = new Array(Math.ceil(deals.length / limit)).fill(null).map((_, index) => index + 1)

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="deals" className="my-2">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="fw-bold m-0">Deals</h3>
            {(permissions["CREATE_DEAL"]) && (<button className="btn btn-primary" onClick={() => handleNew()}>New</button>)}
          </div>
        </div>

        <table className="table table-bordered table-hover shadow-none">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">ID</th>
              <th scope="col" style={{ width: "40%" }} className="align-middle">Title</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Cost</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Price</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Discount</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Sale</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={row.id.toString()}>
                <td className="align-middle text-center">{(index + 1) + ((active - 1) * limit)}</td>
                <td className="align-middle">{row.title}</td>
                <td className="align-middle text-center">Rs. {row.cost?.toFixed(2)}</td>
                <td className="align-middle text-center">Rs. {row.price?.toFixed(2)}</td>
                <td className="align-middle text-center">Rs. {row.discount?.toFixed(2)}</td>
                <td className="align-middle text-center">Rs. {(row.price - row.discount)?.toFixed(2)}</td>
                <td className="align-middle text-center">
                {(permissions["UPDATE_DEAL"]) && (<a className="me-2" onClick={() => handleEdit(row.id)}><i className="far fa-edit"></i></a>)}
                {(permissions["DELETE_DEAL"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="container-fluid">
          <nav aria-label="pagination">
            <ul className="pagination pagination-sm">
              <li className={`page-item ${active === 1 ? "disabled" : ""}`}>
                <a className="page-link" href="#" onClick={() => setActive((previous) => previous -= 1)}>Previous</a>
              </li>
              {pages.map((page) => (
                <li key={page} className={`page-item ${page === active ? "active" : ""}`}><a className="page-link" href="#" onClick={() => setActive(page)}>{page}</a></li>
              ))}
              <li className={`page-item ${active === Math.ceil(deals.length / limit) ? "disabled" : ""}`}>
                <a className="page-link" href="#" onClick={() => setActive((previous) => previous += 1)}>Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </main>
    </Layout>
  )
}

export default Deals
