import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as orderApis from "../../utilities/apis/order"
import * as dispatcherServices from "../../utilities/services/dispatcher"
import { getOrderStatus, getPaymentStatus, isDeal } from "../../utilities/enumerations/constants"
import { getShippingStatus } from "../../utilities/enumerations/functions"
import Layout from "../../components/Layout"

const Order = () => {
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)
  const params = new URLSearchParams(window.location.search)
  const [order, setOrder] = useState(null)

  const handleConfirm = async () => {
    try {
      const response = await dispatcherServices.orderPunched(token, {
        order_id: params.get("id")
      })
      if (!response.status) throw new Error(response.message)

      setOrder({
        ...order,
        order_status: "punched"
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const handlePay = async () => {
    try {
      const response = await orderApis.payOrder(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setOrder({
        ...order,
        payment_status: "paid"
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const getOrder = async () => {
    try {
      const response = await orderApis.getOrder(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setOrder(response.order)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    if (params.has("id"))
      getOrder()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order" className="my-4">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h3 className="fw-bold m-0">Order #: <span className="text-danger fw-bold">P{order?.id}</span></h3>
            {(permissions["PAY_ORDER"]) && (
            <div className="d-flex gap-2">
              {order?.order_status === "pending" && <button className="btn btn-success" onClick={() => handleConfirm()}>Confirm Order</button>}
              {order?.payment_status !== "paid" && <button className="btn btn-primary" onClick={() => handlePay()}>Mark as Paid</button>}
            </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-9">
              <div className="card mb-4">
                <div className="card-body">
                  <table className="table shadow-none">
                    <thead>
                      <tr>
                        <th scope="col" className="fw-bold">Product</th>
                        <th scope="col" className="text-center fw-bold">Price</th>
                        <th scope="col" className="text-center fw-bold">Discount</th>
                        <th scope="col" className="text-center fw-bold">Sale</th>
                        <th scope="col" className="text-center fw-bold">Swap</th>
                        <th scope="col" className="text-center fw-bold">Quantity</th>
                        <th scope="col" className="text-center fw-bold">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.order_lineitems.map((item) => {
                        const swap = isDeal(item.type)
                          ? item.products.reduce((previous, current) => previous += current.swap || 0, 0)
                          : 0

                        return (
                          <tr key={item.id.toString()}>
                            <td className="align-middle small">
                              {item.title}
                              {isDeal(item.type) && <ul className="list-unstyled">
                                {item.products.map((product) => (<li key={`${item.id}-${product.id}`}>- {product.title} x{product.order_quantity}</li>))}
                              </ul>}
                            </td>
                            <td className="align-middle text-center">Rs. {item.price.toFixed(2)}</td>
                            <td className="align-middle text-center">Rs. {(item.discount || 0).toFixed(2)}</td>
                            <td className="align-middle text-center">Rs. {(item.price - (item.discount || 0)).toFixed(2)}</td>
                            <td className="align-middle text-center">Rs. {swap.toFixed(2)}</td>
                            <td className="align-middle text-center">{item.quantity}</td>
                            <td className="align-middle text-center">Rs. {(((item.price - (item.discount || 0)) + swap)).toFixed(2)}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card">
                <div className="card-header bg-white border-bottom-0">
                  <p className="m-0 text-dark fw-bold lead">Customer</p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Name</p>
                    <p className="m-0">{order?.customer?.name}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Mobile</p>
                    <p className="m-0">{order?.customer?.mobile}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Address</p>
                    <p className="m-0">{order?.customer?.address}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mb-4">
                <div className="card-header bg-white border-bottom-0">
                  <p className="m-0 text-dark fw-bold lead">Rider</p>
                </div>
                <div className="card-body d-flex align-items-center">
                  <div className={`driver-icon bg-${order?.driver?.name ? "warning" : "danger"}-light me-3`}>
                    <i className={`bi bi-truck text-${order?.driver?.name ? "warning" : "danger"}`}></i>
                  </div>
                  <div>
                    <p className={`m-0 text-${order?.driver?.name ? "warning" : "danger"}`}>{order?.driver?.name || 'Not assigned'}</p>
                    <p className="m-0 small">{order?.driver?.username}</p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header bg-white border-bottom-0 d-flex justify-content-between align-items-center">
                  <p className="m-0 text-dark fw-bold lead">Order Summary</p>
                  <p className="m-0">{getOrderStatus[order?.order_status]}</p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Payment Status</p>
                    <p className="m-0">{getPaymentStatus[order?.payment_status]}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Shipping Status</p>
                    <p className="m-0"><span className="badge" style={getShippingStatus(order?.ship_datetime).style}>{getShippingStatus(order?.ship_datetime).status}</span></p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Order Date</p>
                    <p className="m-0">{order?.order_datetime ? moment(order.order_datetime).format("DD-MM-YYYY HH:mm") : ""}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Ship Date</p>
                    <p className="m-0">{order?.ship_datetime ? moment(order.ship_datetime).format("DD-MM-YYYY HH:mm") : ""}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Sub Total</p>
                    <p className="m-0">Rs. {order?.total.toFixed(2)}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Discount</p>
                    <p className="m-0">Rs. {order?.discount.toFixed(2)}</p>
                  </div>
                </div>
                <div className="card-footer border-top-0 d-flex justify-content-between align-items-center">
                  <p className="m-0 text-danger fw-bold lead">Total</p>
                  <p className="m-0 text-danger fw-bold lead">Rs. {(order?.total - order?.discount).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Order
