import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as customerApis from "../../utilities/apis/customer"
import * as zoneApis from "../../utilities/apis/zone"
import Layout from "../../components/Layout"

const Customer = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const nameRef = useRef(null)
  const mobileRef = useRef(null)
  const addressRef = useRef(null)
  const tagsRef = useRef(null)
  const lastOrderRef = useRef(null)
  const zoneRef = useRef(null)
  const params = new URLSearchParams(window.location.search)
  const [zones, setZones] = useState([])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const name = e.target.name.value || null
      const mobile = e.target.mobile.value
      const address = e.target.address.value
      const tags = e.target.tags.value || null
      const lastOrder = e.target.lastOrder.value || null
      const zone = e.target.zone.value || null
      const payload = { name, mobile, address, tags, last_order: lastOrder, zone_id: zone }

      if (!params.has("id")) {
        const response = await customerApis.createCustomer(token, payload)
        if (!response.status) throw new Error(response.message)

        nameRef.current.value = ""
        mobileRef.current.value = ""
        addressRef.current.value = ""
        tagsRef.current.value = ""
        lastOrderRef.current.value = ""
        zoneRef.current.value = zones[0].id
      } else {
        payload.id = parseInt(params.get("id"))
        const response = await customerApis.updateCustomer(token, payload)
        if (!response.status) throw new Error(response.message)
      }

      alert("Customer saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const getCustomer = async () => {
    try {
      const response = await customerApis.getCustomer(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      if (response.customer.name) nameRef.current.value = response.customer.name
      mobileRef.current.value = response.customer.mobile
      addressRef.current.value = response.customer.address
      if (response.customer.tags) tagsRef.current.value = response.customer.tags
      if (response.customer.last_order) lastOrderRef.current.value = moment(response.customer.last_order).format("YYYY-MM-DDTHH:mm")
      zoneRef.current.value = response.customer.zone_id
    } catch (error) {
      alert(error.message)
    }
  }

  const getZones = async () => {
    try {
      const response = await zoneApis.getZones(token, "")
      if (!response.status) throw new Error(response.message)

      setZones(response.zones)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getZones()
    if (params.has("id"))
      getCustomer()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customer" className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
              <div className="card">
                <div className="card-header bg-white border-bottom-0 text-center">
                  <h3 className="fw-bold m-0">Customer</h3>
                </div>
                <div className="card-body">
                  <form method="POST" onSubmit={handleSubmit}>
                    <div className="form-floating mb-3">
                      <input ref={nameRef} type="text" className="form-control" id="name" placeholder="Name" />
                      <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile #" required />
                      <label htmlFor="mobile">Mobile #</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={addressRef} type="text" className="form-control" id="address" placeholder="Address" required />
                      <label htmlFor="address">Address</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={tagsRef} type="text" className="form-control" id="tags" placeholder="Tags (Good, Delightful, etc)" />
                      <label htmlFor="tags">Tags (Good, Delightful, etc)</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={lastOrderRef} type="datetime-local" className="form-control" id="lastOrder" placeholder="Last Order" />
                      <label htmlFor="lastOrder">Last Order</label>
                    </div>
                    <div className="form-floating mb-3">
                      <select ref={zoneRef} className="form-select" id="zone">
                        {zones.map((zone) => (
                          <option key={zone.id.toString()} value={zone.id}>{zone.name}</option>
                        ))}
                      </select>
                      <label htmlFor="zone">Select Zone</label>
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary py-3">Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Customer
