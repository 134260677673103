import { useEffect } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import * as userSlice from "../store/user"
import * as userApis from "../utilities/apis/user"
import "../style.css"

const Layout = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)

  const handleLogout = async () => {
    try {
      if (!window.confirm("Are you sure you want to logout?")) return

      const response = await userApis.logout({ token })
      if (!response.status) throw new Error(response.message)

      dispatch(userSlice.logout())
      navigate("/login")
    } catch (error) {
      alert(error.message)
    }
  }

  const validateSession = async () => {
    try {
      const response = await userApis.validateSession({ token })
      if (!response.status) throw new Error(response.message)
    } catch (error) {
      navigate("/login")
    }
  }

  useEffect(() => {
    validateSession()
  }, [])

  return (
    <>
      {
        props.header && <nav className="navbar navbar-expand-lg navbar-dark py-1 no-print">
          <div className="container-fluid">
            <NavLink className="navbar-brand" to="/dashboard">Phonics</NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/dashboard">Dashboard</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/dashboard/process">Process</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/dashboard/dispatch">Dispatch</NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Customers</a>
                  <div className="dropdown-menu">
                    {(permissions["READ_CUSTOMERS"]) && (<NavLink className="dropdown-item" to="/dashboard/customers">Customers</NavLink>)}
                    {(permissions["READ_CAMPAIGNS"]) && (<NavLink className="dropdown-item" to="/dashboard/campaigns">Campaigns</NavLink>)}
                    <NavLink className="dropdown-item" to="/dashboard/order_history">Order History</NavLink>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Products</a>
                  <div className="dropdown-menu">
                    {(permissions["READ_CATEGORIES"]) && (<NavLink className="dropdown-item" to="/dashboard/categories">Categories</NavLink>)}
                    {(permissions["READ_PRODUCTS"]) && (<NavLink className="dropdown-item" to="/dashboard/products">Products</NavLink>)}
                    {(permissions["READ_DEALS"]) && (<NavLink className="dropdown-item" to="/dashboard/deals">Deals</NavLink>)}
                  </div>
                </li>
                <li className="nav-item">
                  {(permissions["READ_DRIVERS"]) && (<NavLink className="nav-link" to="/dashboard/drivers">Riders</NavLink>)}
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Orders</a>
                  <div className="dropdown-menu">
                    {(permissions["READ_ORDERS"]) && (<NavLink className="dropdown-item" to="/dashboard/orders">Orders</NavLink>)}
                    {(permissions["READ_ORDERS"]) && (<NavLink className="dropdown-item" to="/dashboard/trash_orders">Trash Orders</NavLink>)}
                    {(permissions["READ_ORDERS"]) && (<NavLink className="dropdown-item" to="/dashboard/pending_orders">Pending Orders</NavLink>)}
                    {(permissions["READ_REQUESTS"]) && (<NavLink className="dropdown-item" to="/dashboard/requests">Requests</NavLink>)}
                    {(permissions["READ_ORDER_REQUESTS"]) && (<NavLink className="dropdown-item" to="/dashboard/order_requests">Order Requests</NavLink>)}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Reports</a>
                  <div className="dropdown-menu dropdown-menu-end">
                    {(permissions["GET_SUMMARY"]) && (<NavLink className="dropdown-item" to="/dashboard/report/summary">Summary</NavLink>)}
                    {(permissions["GET_CUSTOMER_COUNT_VALUE"]) && (<NavLink className="dropdown-item" to="/dashboard/report/customer">Customer</NavLink>)}
                    {(permissions["GET_ORDERS"]) && (<NavLink className="dropdown-item" to="/dashboard/report/order">Order</NavLink>)}
                    {(permissions["READ_PRODUCTS"]) && (<NavLink className="dropdown-item" to="/dashboard/report/product">Product</NavLink>)}
                    {(permissions["READ_DRIVERS"]) && (<NavLink className="dropdown-item" to="/dashboard/report/rider">Rider</NavLink>)}
                    {(permissions["READ_INCOME_STATEMENTS"]) && (<NavLink className="dropdown-item" to="/dashboard/report/income statement">Income Statement</NavLink>)}
                  </div>
                </li>
                <li className="nav-item">
                  {(permissions["READ_INSIGHTS"]) && (<NavLink className="nav-link" to="/dashboard/insights">Insights</NavLink>)}
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link setting" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><i className="fas fa-cog"></i></a>
                  <div className="dropdown-menu dropdown-menu-end">
                    {(permissions["READ_USERS"]) && (<NavLink className="dropdown-item" to="/dashboard/users">Users</NavLink>)}
                    {(permissions["READ_INCOME_STATEMENTS"]) && (<NavLink className="dropdown-item" to="/dashboard/settings/incomestatement">Income Statement Configuration</NavLink>)}
                    {(permissions["READ_ROLES"]) && (<NavLink className="dropdown-item" to="/dashboard/permissions">Permissions</NavLink>)}
                  </div>
                </li>
                <div className="dropdown-menu dropdown-menu-end">
                  {(permissions["READ_ROLES"]) && (<NavLink className="dropdown-item" to="/dashboard/permissions">Permissions</NavLink>)}
                </div>
                <li className="nav-item">
                  <a className="nav-link text-danger" onClick={handleLogout}><i className="fas fa-power-off"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      }
      {props.children}
      {
        props.footer && <footer>
          <p className="m-0">Phonics &copy; {new Date().getFullYear()}, Powered by <a href="https://interacksolutions.com?source=phonicspk.com" className="text-decoration-none" target="_blank">Interack Solutions LLC</a></p>
        </footer>
      }
    </>
  )
}

export default Layout
