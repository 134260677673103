import GuestLayout from "../components/GuestLayout"
import HomeDeals from "../components/HomeDeals"
import HomeProdcuts from "../components/HomeProducts"

const Home = () => {
  return (
    <GuestLayout
      header={true}
      footer={true}
    >
      <main id="home" className="text-dark">
        <div id="hero" className="h-100 hero">
          <img src="/hero.jpg" />
        </div>

        <HomeDeals />
        <HomeProdcuts />

        <section id="about">
          <div className="container my-5">
            <h1 className="display-1 fw-bold mb-3">About us</h1>

            <p className="fs-4 mb-3">Phonics is the place where you can find all of your household needs. We offer General, Kitchen, Washroom &amp; Bathroom stuff. We do not compromise on quanlity, our motive is to provide the best quality products at your doorstep.</p>

            <div className="row row-cols-2 row-cols-lg-4 g-4 my-5">
              <div className="col">
                <div className="card h-100">
                  <div className="card-body">
                    <ul className="list-unstyled">
                      <h3 className="fw-bold text-primary mb-3">General</h3>
                      <li>- Surf</li>
                      <li>- Gloves</li>
                      <li>- Glass Cleaner</li>
                      <li>- Gel Bleach</li>
                      <li>- Color Bleach</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <div className="card-body">
                    <ul className="list-unstyled">
                      <h3 className="fw-bold text-danger mb-3">Kitchen</h3>
                      <li>- Dishwashing Liquid</li>
                      <li>- Degreaser</li>
                      <li>- Steel Scrub</li>
                      <li>- Black Oil Soap</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <div className="card-body">
                    <ul className="list-unstyled">
                      <h3 className="fw-bold text-warning mb-3">Washroom</h3>
                      <li>- Handwash</li>
                      <ul>
                        <li>Gray</li>
                        <li>Purple</li>
                      </ul>
                      <li>- Phenyl</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <div className="card-body">
                    <ul className="list-unstyled">
                      <h3 className="fw-bold text-success mb-3">Bathroom</h3>
                      <li>- Bodywash</li>
                      <ul>
                        <li>Black</li>
                        <li>Orange</li>
                        <li>Pink</li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="bg-primary-light py-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4 mb-3 mb-lg-0 text-center text-lg-start">
                  <h1 className="display-1 icon text-primary mb-3"><i className="bi bi-tags"></i></h1>
                </div>
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 className="text-primary fw-bold mb-5">Deals &amp; Discounts</h1>
                  <p className="fs-4 mb-2">In this era of inflation regular products prices keeps going higher and higher.</p>
                  <p className="fs-4 mb-5">We keep bringing new deals and discounts on buying multiple products.</p>
                  <a href="https://wa.me/c/923262023423" target="_blank" className="btn btn-lg btn-outline-primary">Deals &amp; Discounts</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact">
          <div className="container my-5">
            <h1 className="display-1 fw-bold mb-3" id="contact">Contact us</h1>

            <p className="fs-4 mb-3">If you have any queries &amp; questions please feel free to ask us</p>

            <div className="row my-5">
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="card">
                  <div className="card-body text-center text-lg-start">
                    <h1 className="display-4 mb-3"><i className="bi bi-phone"></i></h1>
                    <h3 className="fw-bold mb-3">Mobile</h3>
                    <p className="fs-4 mb-3"><a href="tel:03262023423" className="text-decoration-none">03262023423</a></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="card">
                  <div className="card-body text-center text-lg-start">
                    <h1 className="display-4 mb-3"><i className="bi bi-envelope"></i></h1>
                    <h3 className="fw-bold mb-3">Email</h3>
                    <p className="fs-4 mb-3"><a href="mailto:info@phonicspk.com" className="text-decoration-none">info@phonicspk.com</a></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="card">
                  <div className="card-body text-center text-lg-start">
                    <h1 className="display-4 mb-3"><i className="bi bi-globe"></i></h1>
                    <h3 className="fw-bold mb-3">Website</h3>
                    <p className="fs-4 mb-3"><a href="https://phonicspk.com" className="text-decoration-none">phonicspk.com</a></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="card">
                  <div className="card-body text-center text-lg-start">
                    <h1 className="display-4 mb-3"><i className="bi bi-megaphone"></i></h1>
                    <h3 className="fw-bold mb-3">Social Media</h3>
                    <p className="fs-4 mb-3 d-flex flex-row justify-content-center justify-content-lg-start">
                      <a href="https://www.facebook.com/PhonicsSoundsClean" className="text-decoration-none"><i className="fab fa-facebook me-2"></i></a>
                      <a href="https://wa.me/923262023423" className="text-decoration-none"><i className="fab fa-whatsapp me-2"></i></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="bg-warning-light py-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 className="text-warning fw-bold mb-5">Delivery</h1>
                  <p className="fs-4 mb-2">We provide next day delivery acorss Karachi.</p>
                  <p className="fs-4 mb-5">Charges may apply.</p>
                </div>
                <div className="col-lg-4 mb-3 mb-lg-0 text-center text-lg-end">
                  <h1 className="display-1 icon text-warning mb-3"><i className="bi bi-truck"></i></h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="app">
          <div className="bg-black py-5">
            <div className="container my-5">
              <div className="row align-items-center">
                <div className="col-lg-4 mb-4 mb-lg-0 text-center">
                  <img src="/rider-app.png" width="200" />
                </div>
                <div className="col-lg-8 text-center text-lg-start" id="getApp">
                  <h1 className="display-4 fw-bold text-white m-0">Get in the rider's seat and</h1>
                  <h1 className="display-4 fw-bold text-primary mb-5">get paid</h1>

                  <a href="/Phonics.apk" className="btn btn-lg p-3 px-5 btn-primary" download><i className="fab fa-google-play me-2"></i> Rider App</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </GuestLayout>
  )
}

export default Home
