import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import * as reportService from "../utilities/services/report"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  scales: {
    xAxes: [{
      ticks: {
        steps: 10,
      }
    }],
  },
}

const by = {
  DD: "Date",
  Dy: "Day",
  Mon: "Month",
}

const order = {
  DD: "DD",
  Dy: "ID",
  Mon: "MM",
}

const IncomeStatementGraph = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const monthRef = useRef()
  const [orders, setOrders] = useState([])
  const [ordersBy, setOrdersBy] = useState("Mon")
  const [month, setMonth] = useState(false)
  const [report, setReport] = useState("value")

  const getOrders = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getIncomeStatement(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrders(response.income_statements)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleOrdersBy = (orderBy) => {
    setMonth(orderBy !== "Mon")
    setOrdersBy(orderBy)
  }

  const handleMonth = (e) => {
    const params = {
      by: ordersBy,
      order: order[ordersBy],
    }
    if (e.target.value) params.month = e.target.value
    getOrders(params)
  }

  useEffect(() => {
    if (!user) navigate("/")

    getOrders({
      by: ordersBy,
      order: order[ordersBy],
    })
  }, [])

  useEffect(() => {
    const params = {
      by: ordersBy,
      order: order[ordersBy],
    }
    if (monthRef.current) params.month = monthRef.current.value
    getOrders(params)
  }, [ordersBy])

  const data = {
    labels: orders.map(x => x.by),
    datasets: [{
      label: "Orders",
      data: orders.map(x => parseFloat(x[report])),
      borderWidth: 2,
      backgroundColor: (context) => {
        return context.raw > 0 ? "#d5f0e6" : "#f8d4d5"
      },
      borderColor: (context) => {
        return context.raw > 0 ? "#c0d8cf" : "#dfbfc0"
      },
    }]
  }

  return (
    <div className="card mb-4">
      <div className="card-header bg-white">
        <div className="row align-items-center">
          <div className="col-md-3 fw-bold">Order <span className="text-capitalize">{report}</span> by <span className="text-capitalize">{by[ordersBy]}</span></div>
          <div className="col-md-5"></div>
          <div className="col-md-4 d-flex justify-content-end">
            {month && <select ref={monthRef} className="form-select form-select-sm" onChange={handleMonth}>
              <option value="">All</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>}
            <div className="btn-group btn-group-sm ms-2" role="group">
              <button type="button" className={"btn btn-light " + (ordersBy === "DD" ? "active" : "")} onClick={() => handleOrdersBy("DD")}>Date</button>
              <button type="button" className={"btn btn-light " + (ordersBy === "Dy" ? "active" : "")} onClick={() => handleOrdersBy("Dy")}>Day</button>
              <button type="button" className={"btn btn-light " + (ordersBy === "Mon" ? "active" : "")} onClick={() => handleOrdersBy("Mon")}>Month</button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        {orders.length > 0 && <Bar options={options} data={data} height={75} />}
      </div>
    </div>
  )
}

export default IncomeStatementGraph
