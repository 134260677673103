import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as requestApis from "../../utilities/apis/request"
import { getRequestStatus } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"

const Requests = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const formRef = useRef(null)
  const [requests, setRequests] = useState([])
  const [count, setCount] = useState(0)
  const [selected, setSelected] = useState([])
  const [active, setActive] = useState(1)
  const limit = 10
  const offset = 0

  const handleView = (id) => {
    navigate(`/dashboard/request?id=${id}`)
  }

  const handleSearch = () => {
    const where = {}
    const status = []

    if (formRef.current.from_date.value && formRef.current.from_time.value && formRef.current.to_date.value && formRef.current.to_time.value) {
      where.from_datetime = moment(`${formRef.current.from_date.value} ${formRef.current.from_time.value}`).format("YYYY-MM-DD HH:mm")
      where.to_datetime = moment(`${formRef.current.to_date.value} ${formRef.current.to_time.value}`).format("YYYY-MM-DD HH:mm")
    } else {
      if (formRef.current.from_date.value && formRef.current.to_date.value) {
        where.from_date = moment(formRef.current.from_date.value).format("YYYY-MM-DD")
        where.to_date = moment(formRef.current.to_date.value).format("YYYY-MM-DD")
      }
    }

    if (formRef.current.requested.checked) status.push("requested")
    if (formRef.current.completed.checked) status.push("completed")
    if (status.length > 0) where.status = status.join(",")

    getRequests(where)
  }

  const handleReset = () => {
    getRequests()
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await requestApis.deleteRequest(token, id)
      if (!response.status) throw new Error(response.message)

      setRequests((previous) => {
        const orders = previous.filter(x => x.id !== id)
        return [...orders]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const handleAll = (e) => {
    const updatedRequests = requests.map(request => ({ ...request, checked: e.target.checked }))

    if (e.target.checked)
      setSelected(requests.filter(request => request.status === "requested").map(request => ({ id: request.id, order_ids: request.order_ids })))
    else
      setSelected([])

    setRequests(updatedRequests)
  }

  const handleChange = (id, order_ids) => {
    if (!selected.find(x => x.id === id && x.order_ids === order_ids))
      setSelected((previous) => {
        previous.push({ id, order_ids })
        return [...previous]
      })
    else
      setSelected((previous) => {
        return previous.filter(x => x.id !== id && x.order_ids !== order_ids)
      })
  }

  const handlePay = async () => {
    try {
      if (!selected.length) throw new Error("Please select at least one request")

      const ids = selected.map(x => x.id)
      const orderIds = selected.map(x => x.order_ids)
      const payload = {
        ids: ids,
        order_ids: orderIds,
      }

      const response = await requestApis.completeRequests(token, payload)

      if (!response.status) throw new Error(response.message)

      setRequests((prevRequests) => {
        return prevRequests.map((request) => {
          if (ids.includes(request.id)) {
            return {
              ...request,
              status: "completed"
            }
          }
          return request
        })
      })
      alert("Selected requests have been marked as completed")
    } catch (error) {
      alert(error.message)
    }
  }

  const handlePageChange = (page, offset) => {
    getRequests({ limit, offset })
    setActive(page)
  }

  const getRequests = async (params = {}) => {
    try {
      const query = new URLSearchParams({limit, offset, ...params})

      const response = await requestApis.getRequests(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setRequests(response.requests)
      setCount(response.count);
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getRequests()
  }, [])
  
  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="orders" className="my-2">
        <form ref={formRef}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h3 className="fw-bold m-0">Requests</h3>
              {(permissions["COMPLETE_REQUESTS"]) && (<button type="button" className="btn btn-sm btn-success" onClick={handlePay}>Mark as Paid</button>)}
            </div>

            <div className="row align-items-center mb-2">
              <div className="col-md-3 pe-1">
                <div className="d-flex align-items-center">
                  <label className="col-form-label">From</label>
                  <div className="input-group input-group-sm ms-1">
                    <input type="date" className="form-control bg-light" name="from_date" />
                    <input type="time" className="form-control bg-light" name="from_time" />
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-1">
                <div className="d-flex align-items-center">
                  <label className="col-form-label">To</label>
                  <div className="input-group input-group-sm ms-1">
                    <input type="date" className="form-control bg-light" name="to_date" />
                    <input type="time" className="form-control bg-light" name="to_time" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 ps-1 text-end">
                <div className="btn-group btn-group-sm" role="group">
                  <button type="button" className="btn btn-sm btn-light text-primary" onClick={handleSearch}><i className="fas fa-search"></i></button>
                  <button type="reset" className="btn btn-sm btn-light text-danger" onClick={handleReset}><i className="fas fa-times"></i></button>
                </div>
                <div className="btn-group btn-group-sm ms-2" role="group">
                  <input type="checkbox" className="btn-check" name="requested" id="requested" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="requested">Requested</label>
                  <input type="checkbox" className="btn-check" name="completed" id="completed" autoComplete="off" onChange={handleSearch} />
                  <label className="btn btn-sm btn-light" htmlFor="completed">Completed</label>
                </div>
              </div>
            </div>
          </div>

          <table className="table table-bordered table-hover shadow-none text-dark">
            <thead className="table-light">
              <tr>
                <th scope="col" className="align-middle text-center">
                  <input className="form-check-input" type="checkbox" id="all" value="all" onChange={handleAll} checked={requests.filter(request => request.checked).length} />
                  <span className="ms-1">({requests.filter(request => request.status === "requested" && request.checked).length})</span>
                </th>
                <th scope="col" style={{ width: "9%" }} className="align-middle">Req #</th>
                <th scope="col" style={{ width: "9%" }} className="align-middle">Date</th>
                <th scope="col" style={{ width: "14%" }} className="align-middle">Rider</th>
                <th scope="col" style={{ width: "18%" }} className="align-middle">Note</th>
                <th scope="col" style={{ width: "9%" }} className="align-middle text-center">Status</th>
                <th scope="col" style={{ width: "9%" }} className="align-middle text-center">Total</th>
                <th scope="col" style={{ width: "9%" }} className="align-middle text-center">Commission</th>
                <th scope="col" style={{ width: "9%" }} className="align-middle text-center">Due</th>
                <th scope="col" style={{ width: "9%" }} className="align-middle text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((row) => {
                return (
                  <tr key={row.id.toString()} onDoubleClick={() => handleView(row.id)}>
                    <td className="align-middle text-center">{row.status === "requested" && <input className="form-check-input" type="checkbox" id={row.id} value={row.id} onChange={() => handleChange(row.id, row.order_ids)} checked={selected.find(x => x.id === row.id && x.order_ids === row.order_ids)} />}</td>
                    <td className="align-middle">PINV{row.id}</td>
                    <td className="align-middle">{moment(row.datetime).format("DD-MM-YYYY")}</td>
                    <td className="align-middle">{row.driver.name}</td>
                    <td className="align-middle">{row.driver_note}</td>
                    <td className="align-middle text-center">{getRequestStatus[row.status]}</td>
                    <td className="align-middle text-center">Rs. {row.total.toFixed(2)}</td>
                    <td className="align-middle text-center">Rs. {row.commission.toFixed(2)}</td>
                    <td className="align-middle text-center">Rs. {(row.total - row.commission).toFixed(2)}</td>
                    <td className="align-middle text-center">
                    {(permissions["READ_REQUEST"]) && (<a className="me-2" onClick={() => handleView(row.id)}><i className="far fa-clipboard"></i></a>)}
                    {(permissions["DELETE_REQUEST"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className="container-fluid">
            <nav aria-label="pagination">
              <Pagination
                currentPage={active}
                totalCount={count}
                pageSize={limit}
                offset={offset}
                siblingCount={3}
                onPageChange={handlePageChange}
              />
            </nav>
          </div>
        </form>
      </main>
    </Layout>
  )
}

export default Requests
