import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as productApis from "../../utilities/apis/product"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"

const Products = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const [products, setProducts] = useState([])
  const [rows, setRows] = useState([])
  const [active, setActive] = useState(1)
  const limit = 10

  const handleNew = () => {
    navigate("/dashboard/product")
  }

  const handleEdit = (id) => {
    navigate(`/dashboard/product?id=${id}`)
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await productApis.deleteProduct(token, id)
      if (!response.status) throw new Error(response.message)

      setProducts((previous) => {
        const products = previous.filter(x => x.id !== id)
        return [...products]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const getProducts = async () => {
    try {
      const response = await productApis.getProducts(token, "")
      if (!response.status) throw new Error(response.message)

      setProducts(response.products)
      setRows(response.products.slice(0, limit))
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getProducts()
  }, [])

  useEffect(() => {
    setRows(products.slice((active - 1) * limit, active * limit))
  }, [products.length])

  useEffect(() => {
    setRows(products.slice((active - 1) * limit, active * limit))
  }, [active])

  const pages = new Array(Math.ceil(products.length / limit)).fill(null).map((_, index) => index + 1)

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="products" className="my-2">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="fw-bold m-0">Products</h3>
            {(permissions["CREATE_PRODUCT"]) && (<button className="btn btn-primary" onClick={() => handleNew()}>New</button>)}
          </div>
        </div>

        <table className="table table-bordered table-hover shadow-none">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">ID</th>
              <th scope="col" style={{ width: "32%" }} className="align-middle">Title</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Cost</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Price</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Quantity</th>
              <th scope="col" style={{ width: "18%" }} className="align-middle text-center">Category</th>
              <th scope="col" style={{ width: "10%" }} className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={row.id.toString()}>
                <td className="align-middle text-center">{(index + 1) + ((active - 1) * limit)}</td>
                <td className="align-middle">{row.title}</td>
                <td className="align-middle text-center">Rs. {row.cost?.toFixed(2)}</td>
                <td className="align-middle text-center">Rs. {row.price?.toFixed(2)}</td>
                <td className="align-middle text-center">{row.quantity}</td>
                <td className="align-middle text-center">{row.category.name}</td>
                <td className="align-middle text-center">
                {(permissions["UPDATE_PRODUCT"]) && (<a className="me-2" onClick={() => handleEdit(row.id)}><i className="far fa-edit"></i></a>)}
                {(permissions["DELETE_PRODUCT"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="container-fluid">
          <nav aria-label="pagination">
            <Pagination
              currentPage={active}
              totalCount={products.length}
              pageSize={limit}
              siblingCount={3}
              onPageChange={page => setActive(page)}
            />
          </nav>
        </div>
      </main>
    </Layout>
  )
}

export default Products
