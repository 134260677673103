import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import * as reportService from "../utilities/services/report"
import * as constants from "../utilities/enumerations/constants"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  scales: {
    xAxes: [{
      ticks: {
        steps: 10,
      }
    }],
  },
}

const by = {
  DD: "Date",
  Dy: "Day",
  Mon: "Month",
  YYYY: "Year",
}

const order = {
  DD: "DD",
  Dy: "Dy",
  Mon: "MM",
  YYYY: "YYYY"
}

const OrderCountValue = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const monthRef = useRef()
  const yearRef = useRef()
  const [orders, setOrders] = useState([])
  const [ordersBy, setOrdersBy] = useState("Mon")
  const [year, setYear] = useState("")
  const [month, setMonth] = useState("")
  const [report, setReport] = useState("count")


  const getOrders = async (params) => {
    try {
      const query = new URLSearchParams(params)

      const response = await reportService.getOrderCountValue(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrders(response.orders)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleOrdersBy = (orderBy) => {
    setMonth(orderBy !== "Mon")
    setOrdersBy(orderBy)
  }

  useEffect(() => {
    if (!user) navigate("/")

    getOrders({
      by: ordersBy,
      order: order[ordersBy],
    })
  }, [])

  useEffect(() => {
    const params = {
      by: ordersBy,
      order: order[ordersBy]
    }
    if (monthRef.current) params.month = monthRef.current.value
    if (yearRef.current) params.year = yearRef.current.value

    getOrders(params)
  }, [ordersBy, month, year])

  const data = {
    labels: orders.map(x => x.by),
    datasets: [{
      label: "Orders",
      data: orders.map(x => parseFloat(x[report])),
      backgroundColor: "#d5f0e6",
    }]
  }

  return (
    <div className="card mb-4">
      <div className="card-header bg-white">
        <div className="row align-items-center">
          <div className="col-md-6 fw-bold">Order <span className="text-capitalize">{report}</span> by <span className="text-capitalize">{by[ordersBy]}</span></div>
          <div className="col-md-6 d-flex gap-2 justify-content-end">
            {ordersBy === "DD" && (
              <select ref={monthRef} className="form-select form-select-sm w-25" onChange={(e) => setMonth(e.target.value)}>
                {constants.months.map((m) => (
                <option value={m.value}>{m.name}</option>
                ))}
              </select>
            )}
            {ordersBy === "Dy" && (
              <select ref={monthRef} className="form-select form-select-sm w-25" onChange={(e) => setMonth(e.target.value)}>
                {constants.months.map((m) => (
                <option value={m.value}>{m.name}</option>
                ))}
              </select>
            )}
            {ordersBy === "Mon" && (
              <select ref={yearRef} className="form-select form-select-sm w-25" onChange={(e) => setYear(e.target.value)}>
                {constants.years.map((o) => (
                  <option value={o.value}>{o.name}</option>
                ))}
              </select>
            )}
            {ordersBy === "DD" && (
              <select ref={yearRef} className="form-select form-select-sm w-25" onChange={(e) => setYear(e.target.value)}>
                {constants.years.map((o) => (
                  <option value={o.value}>{o.name}</option>
                ))}
              </select>
            )}
            {ordersBy === "Dy" && (
              <select ref={yearRef} className="form-select form-select-sm w-25" onChange={(e) => setYear(e.target.value)}>
                {constants.years.map((o) => (
                  <option value={o.value}>{o.name}</option>
                ))}
              </select>
            )}
            <div className="btn-group btn-group-sm" role="group">
              <button type="button" className={"btn btn-light " + (ordersBy === "DD" ? "active" : "")} onClick={() => handleOrdersBy("DD")}>Date</button>
              <button type="button" className={"btn btn-light " + (ordersBy === "Dy" ? "active" : "")} onClick={() => handleOrdersBy("Dy")}>Day</button>
              <button type="button" className={"btn btn-light " + (ordersBy === "Mon" ? "active" : "")} onClick={() => handleOrdersBy("Mon")}>Month</button>
              <button type="button" className={"btn btn-light " + (ordersBy === "YYYY" ? "active" : "")} onClick={() => handleOrdersBy("YYYY")}>Year</button>
            </div>
            <div className="btn-group btn-group-sm" role="group">
              <button type="button" className={"btn btn-light " + (report === "value" ? "active" : "")} onClick={() => setReport("value")}>Value</button>
              <button type="button" className={"btn btn-light " + (report === "count" ? "active" : "")} onClick={() => setReport("count")}>Count</button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        {orders.length > 0 && <Bar options={options} data={data} height={75} />}
      </div>
    </div>
  )
}

export default OrderCountValue
