import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as dealApis from "../utilities/apis/deal"

const HomeDeals = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const [deals, setDeals] = useState([])

  const getDeals = async () => {
    try {
      const response = await dealApis.getHomeDeals(token, "")
      if (!response.status) throw new Error(response.message)

      setDeals(response.deals)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getDeals()
  }, [])

  const handleView = (slug) => {
    navigate(`/deal/${slug}`)
  }

  return (
    deals.length && <section className="my-5">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h3 className="display-1 fw-bold mb-3" id="deals">Deals</h3>
        </div>

        <div className="row g-1">
          {deals.map((deal) => (
            <div key={deal.id.toString()} className="col-lg-2 col-md-6">
              <div className="card">
                <img src={deal.thumbnail} className="card-img-top img-fluid deal-image" alt={deal.title} />
                <div className="card-body p-2">
                  <j5 className="text-dark fw-bold m-0 text-truncate">{deal.title}</j5>
                  {/* <p className="card-text small text-muted m-0">{deal.description}</p> */}
                  <p className="card-text small text-muted m-0">Up to {deal.products.length} products</p>
                  <p className="text-primary fw-bold mt-3 m-0">Rs. {(deal.price - deal.discount).toFixed(2)}</p>
                </div>
                <div className="card-footer p-0">
                  <button className="btn btn-sm btn-link w-100" onClick={() => handleView(deal.slug)}>View</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HomeDeals