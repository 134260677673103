import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createDriver = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/driver`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const updateDriver = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/driver`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteDriver = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/driver/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getDrivers = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/drivers?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getDriver = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/driver?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}