import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createZone = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/zone`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const updateZone = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/zone`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteZone = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/zone/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getZones = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/zones?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getZone = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/zone?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}