import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as incomeStatementApis from "../../utilities/apis/income_statement"
import Layout from "../../components/Layout"
import IncomeStatementTable from "../../components/IncomeStatementTable"
import IncomeStatementGraph from "../../components/IncomeStatementGraph"

const ReportIncomeStatement = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const fromRef = useRef(null)
  const toRef = useRef(null)
  const [type, setType] = useState("table")
  const [profitIn, setProfitIn] = useState("value")

  const handleSubmit = async () => {
    try {
      const from = moment(fromRef.current.value).format()
      const to = moment(`${toRef.current.value} 23:59`).format()
      const payload = { from, to }

      const response = await incomeStatementApis.createIncomeStatement(token, payload)
      if (!response.status) throw new Error(response.message)

      alert("Income statement saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const handleDelete = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete all income statements? You will lost all your data.")) return

      const response = await incomeStatementApis.deleteIncomeStatements(token)
      if (!response.status) throw new Error(response.message)

      alert("Income statements deleted")
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    fromRef.current.value = moment().format('YYYY-MM-01')
    toRef.current.value = moment().format('YYYY-MM-DD')
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-2 text-dark">
        <div className="container-fluid">
          <div className="row align-items-center mb-2">
            <div className="col-5 pe-1">
              <h3 className="fw-bold m-0">Income Statement</h3>
            </div>
            <div className="col-2 px-1 text-end">
              <div className="btn-group btn-group-sm ms-2" role="group">
                <button type="button" className={"btn btn-light " + (profitIn === "value" ? "active" : "")} onClick={() => setProfitIn("value")}>Value</button>
                <button type="button" className={"btn btn-light " + (profitIn === "percent" ? "active" : "")} onClick={() => setProfitIn("percent")}>Percent</button>
              </div>
              <div className="btn-group btn-group-sm ms-2" role="group">
                <button type="button" className={"btn btn-light " + (type === "table" ? "active" : "")} onClick={() => setType("table")}>Table</button>
                <button type="button" className={"btn btn-light " + (type === "graph" ? "active" : "")} onClick={() => setType("graph")}>Graph</button>
              </div>
            </div>
            <div className="col-4 px-1 text-end">
              <div className="input-group input-group-sm flex-grow-1">
                <input type="date" ref={fromRef} className="form-control" />
                <input type="date" ref={toRef} className="form-control" />
                <button className="btn btn-sm btn-primary" onClick={handleSubmit}>Generate Statement</button>
              </div>
            </div>
            <div className="col-md-1 ps-1">
              <button className="btn btn-sm btn-danger w-100" onClick={handleDelete}>Delete All</button>
            </div>
          </div>

          {type === "graph" && <IncomeStatementGraph profitIn={profitIn} />}
        </div>
        {type === "table" && <IncomeStatementTable profitIn={profitIn} />}
      </main>
    </Layout>
  )
}

export default ReportIncomeStatement
