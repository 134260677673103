import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as driverApis from "../../utilities/apis/driver"
import Layout from "../../components/Layout"

const Drivers = () => {
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)
  const [drivers, setDrivers] = useState([])
  const [status, setStatus] = useState("true")

  const handleNew = () => {
    navigate("/dashboard/driver")
  }

  const handleEdit = (id) => {
    navigate(`/dashboard/driver?id=${id}`)
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await driverApis.deleteDriver(token, id)
      if (!response.status) throw new Error(response.message)

      setDrivers((previous) => {
        const drivers = previous.filter(x => x.id !== id)
        return [...drivers]
      })
    } catch (error) {
    }
  }

  const getDrivers = async () => {
    try {
      const query = new URLSearchParams({ active: status })
      const response = await driverApis.getDrivers(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setDrivers(response.drivers)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getDrivers()
  }, [status])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="drivers" className="my-2">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="fw-bold flex-grow-1 m-0">Riders</h3>
            <div className="d-flex gap-2">
              <select className="form-select driver-status" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              {(permissions["CREATE_DRIVER"]) && (<button className="btn btn-primary" onClick={handleNew}>New</button>)}
            </div>
          </div>
        </div>

        <table className="table table-bordered table-hover shadow-none">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "10%" }} className="text-center">ID</th>
              <th scope="col" style={{ width: "40%" }}>Name</th>
              <th scope="col" style={{ width: "25%" }}>Mobile #</th>
              <th scope="col" style={{ width: "15%" }} className="text-center">Commission</th>
              <th scope="col" style={{ width: "10%" }} className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((row) => (
              <tr key={row.id.toString()}>
                <td className="align-middle text-center">{row.id}</td>
                <td className="align-middle">{row.name}</td>
                <td className="align-middle">{row.mobile}</td>
                <td className="align-middle text-center">{row.commission && `Rs. ${row.commission.toFixed(2)}`}</td>
                <td className="align-middle text-center">
                {(permissions["UPDATE_DRIVER"]) && (<a className="me-2" onClick={() => handleEdit(row.id)}><i className="far fa-edit"></i></a>)}
                {(permissions["DELETE_DRIVER"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </Layout>
  )
}

export default Drivers
