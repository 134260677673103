import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as orderApis from "../../utilities/apis/order"
import * as dispatcherServices from "../../utilities/services/dispatcher"
import { isDeal } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"

const Process = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const [leftItems, setLeftItems] = useState([])
  const [rightItems, setRightItems] = useState([])

  const getOrders = async () => {
    try {
      const query = new URLSearchParams({
        order_status: "punched,assigned"
      })
      const response = await orderApis.getOrders(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setLeftItems(response.orders.filter((order) => !order.processed))
      setRightItems(response.orders.filter((order) => order.processed))
    } catch (error) {
      alert(error.message)
    }
  }

  const handleProcessed = async (id) => {
    try {
      const payload = [{
        order_id: id
      }]
      const response = await dispatcherServices.orderProcessed(token, payload)
      if (!response.status) throw new Error(response.message)

      getOrders()
    } catch (error) {
      alert(error.message)
    }
  }

  const handleUnprocessed = async (id) => {
    try {
      const payload = [{
        order_id: id
      }]
      const response = await dispatcherServices.orderUnprocessed(token, payload)
      if (!response.status) throw new Error(response.message)

      getOrders()
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getOrders()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order" className="flex-column">
        <div className="p-2">
          <h3 className="fw-bold m-0">Processing <small>({leftItems.length})</small></h3>
        </div>

        <div className="flex-grow-1 d-flex overflow-y-auto border-top">
          <section className="w-75 border-end">
            <div className="accordion accordion-flush">
              {leftItems.map((order, index) => {
                return (
                  <div key={`left-${order.id}`} className="d-flex">
                    <div className="accordion-item flex-grow-1">
                      <h2 className="accordion-header" id={`heading-left-${index}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-left-${index}`} aria-expanded="true" aria-controls={`collapse-left-${index}`}>
                          {order.driver && <>
                            <span className="fw-bold me-1">RIDER:</span><span className="me-3">{order.driver.name}</span> |
                          </>}
                          <span className={`fw-bold ${order.driver && 'ms-3'} me-1`}>ORDER #:</span><span className="me-3">P{order.id}</span> |
                          <span className="fw-bold ms-3 me-1">ZONE:</span><span className="me-3">{order.zone.name}</span> |
                          <span className="ms-3 me-1">{moment(order.ship_datetime).format("DD-MM-YY HH:mm")}</span>
                        </button>
                      </h2>
                      <div id={`collapse-left-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-left-${index}`} data-bs-parent="#accordion-left">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-4">
                              <h5>Customer</h5>
                              <p className="m-0">{order.customer.name}</p>
                              <p>{order.customer.mobile}</p>

                              <h5>Rider</h5>
                              <p className="m-0">{order.driver && order.driver.name}</p>
                            </div>
                            <div className="col-8">
                              <h5>Order Lineitems</h5>
                              <ul className="list-unstyled">
                                {order.order_lineitems.map((item, index) => {
                                  if (isDeal(item.type))
                                    return (
                                      <>
                                        <li key={`left-${index}`}>{item.title} x{item.quantity} = {(item.quantity * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}</li>
                                        {<ul className="list-unstyled">
                                          {item.products.map((product) => (<li key={`${item.id}-${product.id}-${index}`}>- {product.title} x{product.order_quantity}</li>))}
                                        </ul>}
                                      </>
                                    )
                                  else
                                    return (
                                      <li key={`left-${index}`}>{item.title} x{item.quantity} = {(item.quantity * item.price).toFixed(2)}</li>
                                    )
                                })}
                                <li key="left-total">Total: {(order.total - order.discount).toFixed(2)}</li>
                              </ul>
                            </div>
                          </div>

                          <div className="text-end">
                            <button className="btn btn-success shadow-none" onClick={() => handleProcessed(order.id)}>Mark as Processed</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </section>
          <aside className="w-25 border-start">
            <div className="accordion accordion-flush" id="accordion-right">
              {rightItems.map((order, index) => {
                return (
                  <div key={`right-${order.id}`} className="d-flex">
                    <div className="accordion-item flex-grow-1">
                      <h2 className="accordion-header" id={`heading-right-${index}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-right-${index}`} aria-expanded="true" aria-controls={`collapse-right-${index}`}>
                          {order.driver && <>
                            <span className="fw-bold me-1">RIDER:</span><span className="me-3">{order.driver.name}</span> |
                          </>}
                          <span className={`fw-bold ${order.driver && 'ms-3'} me-1`}>ORDER #:</span><span>P{order.id}</span>
                        </button>
                      </h2>
                      <div id={`collapse-right-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-right-${index}`} data-bs-parent="#accordion-right">
                        <div className="accordion-body">
                          <h5>Customer</h5>
                          <p className="m-0">{order.customer.name}</p>
                          <p>{order.customer.mobile}</p>

                          <h5>Order Lineitems</h5>
                          <ul className="list-unstyled">
                            {order.order_lineitems.map((item, index) => {
                              if (isDeal(item.type))
                                return (
                                  <>
                                    <li key={`right-${index}`}>{item.title} x{item.quantity} = {(item.quantity * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}</li>
                                    {<ul className="list-unstyled">
                                      {item.products.map((product) => (<li key={`${item.id}-${product.id}-${index}`}>- {product.title} x{product.order_quantity}</li>))}
                                    </ul>}
                                  </>
                                )
                              else
                                return (
                                  <li key={`right-${index}`}>{item.title} x{item.quantity} = {(item.quantity * item.price).toFixed(2)}</li>
                                )
                            })}
                            <li key="right-total">Total: {(order.total - order.discount).toFixed(2)}</li>
                          </ul>

                          <div className="text-end">
                            <button className="btn btn-danger shadow-none" onClick={() => handleUnprocessed(order.id)}>Mark as Unprocessed</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </aside>
        </div>
      </main>
    </Layout>
  )
}

export default Process
