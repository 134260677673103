import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const upsertOrderHistory = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/order_history`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getOrderHistories = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/order_histories?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getOrderHistory = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/order_history?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

