import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createDeal = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/deal`, payload, {
    headers: {
      "Token": token,
      "Content-Type": "multipart/form-data"
    }
  })
  return response
}

export const updateDeal = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/deal`, payload, {
    headers: {
      "Token": token,
      'Content-Type': 'multipart/form-data',
    }
  })
  return response
}

export const deleteDeal = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/deal/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getDeals = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/deals?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getHomeDeals = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/deals/home?${query}`)
  return response
}

export const getDeal = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/deal?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getHomeDeal = async (slug) => {
  const { data: response } = await axios.get(`${apiUrl}/deal/home?slug=${slug}`)
  return response
}