import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as incomeStatementApis from "../utilities/apis/income_statement"

const by = {
  DD: "Date",
  Dy: "Day",
  Mon: "Month",
}

const order = {
  DD: "DD",
  Dy: "ID",
  Mon: "MM",
}

const IncomeStatementTable = (props) => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const monthRef = useRef()
  const [orders, setOrders] = useState([])
  const [ordersBy, setOrdersBy] = useState("Mon")
  const [month, setMonth] = useState(false)
  const [report, setReport] = useState("value")

  const handleEdit = async (index) => {
    try {
      const response = await incomeStatementApis.updateIncomeStatement(token, orders[index])
      if (!response.status) throw new Error(response.message)

      setOrders((previous) => {
        previous[index] = response.income_statement
        return [...previous]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await incomeStatementApis.deleteIncomeStatement(token, id)
      if (!response.status) throw new Error(response.message)

      setOrders(() => orders.filter(order => order.id !== id))
    } catch (error) {
      alert(error.message)
    }
  }

  const getOrders = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await incomeStatementApis.getIncomeStatements(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrders([...response.income_statements])
    } catch (error) {
      alert(error.message)
    }
  }

  const handleItemChange = (e, index, key) => {
    setOrders(previous => {
      previous[index][key] = parseFloat(e.target.value)
      return [...previous]
    })
  }

  const handleOrdersBy = (orderBy) => {
    setMonth(orderBy !== "Mon")
    setOrdersBy(orderBy)
  }

  const handleMonth = (e) => {
    const params = {
      by: ordersBy,
      order: order[ordersBy],
    }
    if (e.target.value) params.month = e.target.value
    getOrders(params)
  }

  useEffect(() => {
    if (!user) navigate("/")

    getOrders({
      by: ordersBy,
      order: order[ordersBy],
    })
  }, [])

  useEffect(() => {
    const params = {
      by: ordersBy,
      order: order[ordersBy],
    }
    if (monthRef.current) params.month = monthRef.current.value
    getOrders(params)
  }, [ordersBy])

  return (
    <>
      <table className="table table-bordered table-hover shadow-none">
        <thead className="table-light">
          <tr>
            <th scope="col" style={{ width: "16%" }} className="align-middle">Date</th>
            <th scope="col" style={{ width: "11%" }} className="align-middle text-center">Price</th>
            <th scope="col" style={{ width: "11%" }} className="align-middle text-center">Cost</th>
            <th scope="col" style={{ width: "11%" }} className="align-middle text-center">Advertisement</th>
            <th scope="col" style={{ width: "11%" }} className="align-middle text-center">Labor</th>
            <th scope="col" style={{ width: "11%" }} className="align-middle text-center">Packing</th>
            <th scope="col" style={{ width: "11%" }} className="align-middle text-center">Commission</th>
            <th scope="col" style={{ width: "11%" }} className="align-middle text-center">Total</th>
            <th scope="col" style={{ width: "7%" }} className="align-middle text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((row, index) => (
            <tr key={row.id.toString()}>
              <td className="align-middle">{row.date}</td>
              <td className="align-middle text-center">Rs. {row.price?.toFixed(2)}</td>
              <td className="align-middle text-center">
                {
                  props.profitIn === "value"
                    ? `Rs. ${row.cost?.toFixed(2)}`
                    : `${((row.cost / row.price) * 100).toFixed(2)}%`
                }
              </td>
              <td className="align-middle text-center">
                {
                  props.profitIn === "value"
                    ? <input type="text" className="form-control form-control-sm" defaultValue={row.advertisement} onKeyUp={(e) => handleItemChange(e, index, "advertisement")} />
                    : `${((row.advertisement / row.price) * 100).toFixed(2)}%`
                }
              </td>
              <td className="align-middle text-center">
                {
                  props.profitIn === "value"
                    ? <input type="text" className="form-control form-control-sm" defaultValue={row.labor} onKeyUp={(e) => handleItemChange(e, index, "labor")} />
                    : `${((row.labor / row.price) * 100).toFixed(2)}%`
                }
              </td>
              <td className="align-middle text-center">
                {
                  props.profitIn === "value"
                    ? <input type="text" className="form-control form-control-sm" defaultValue={row.packing} onKeyUp={(e) => handleItemChange(e, index, "packing")} />
                    : `${((row.price / 100_000) * row.packing).toFixed(2)}%`
                }
              </td>
              <td className="align-middle text-center">
                {
                  props.profitIn === "value"
                    ? `Rs. ${row.commission?.toFixed(2)}`
                    : `${((row.commission / row.price) * 100).toFixed(2)}%`
                }
              </td>
              <td className="align-middle text-center">
                {
                  props.profitIn === "value"
                    ? <span className={`badge rounded-pill ${row.total > 0 ? "badge-success-light text-success" : "badge-danger-light text-danger"}`}><i className={`fas fa-arrow-circle-${row.total > 0 ? "up" : "down"} me-1`}></i> {row.total.toFixed(2)}</span>
                    : `${((row.total / row.price) * 100).toFixed(2)}%`
                }
              </td>
              <td className="align-middle text-center">
                {props.profitIn === "value" && <a className="me-2" onClick={() => handleEdit(index)}><i className="far fa-save"></i></a>}
                <a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default IncomeStatementTable
