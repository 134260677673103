import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import * as reportService from "../utilities/services/report"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  scales: {
    xAxes: [{
      ticks: {
        steps: 10,
      }
    }],
  },
}

const CustomerZoneCount = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const [zones, setZones] = useState([])
  const [limit, setLimit] = useState("20")

  const getCustomerZoneCount = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getCustomerZoneCount(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setZones(response.zones)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getCustomerZoneCount({
      limit,
    })
  }, [])

  useEffect(() => {
    getCustomerZoneCount({
      limit,
    })
  }, [limit])

  const data = {
    labels: zones.map(x => x.name),
    datasets: [{
      label: "Zones",
      data: zones.map(x => parseFloat(x.count)),
      backgroundColor: "#d5f0e6",
    }]
  }

  return (
    <div className="card mb-4">
      <div className="card-header bg-white">
        <div className="row align-items-center">
          <div className="col-md-3 fw-bold">Customer Zone Count</div>
          <div className="col-md-5"></div>
          <div className="col-md-4 d-flex justify-content-end">
            <div className="btn-group btn-group-sm ms-2" role="group">
              <button type="button" className={"btn btn-light " + (limit === "5" ? "active" : "")} onClick={() => setLimit("5")}>5</button>
              <button type="button" className={"btn btn-light " + (limit === "10" ? "active" : "")} onClick={() => setLimit("10")}>10</button>
              <button type="button" className={"btn btn-light " + (limit === "20" ? "active" : "")} onClick={() => setLimit("20")}>20</button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        {zones.length > 0 && <Bar options={options} data={data} height={75} />}
      </div>
    </div>
  )
}

export default CustomerZoneCount
