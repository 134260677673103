import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const updateRole = async (token, payload) => {
    const { data: response } = await axios.put(`${apiUrl}/role`, payload, {
      headers: {
        "Token": token,
      }
    })
    return response
  }

export const getRoles = async (token, query) => {
const { data: response } = await axios.get(`${apiUrl}/roles?${query}`, {
    headers: {
    "Token": token
    }
})
return response
}

export const getRole = async (token, id) => {
const { data: response } = await axios.get(`${apiUrl}/role?id=${id}`, {
    headers: {
    "Token": token
    }
})
return response
}