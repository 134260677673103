import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as dealApis from "../../utilities/apis/deal"
import Layout from "../../components/Layout"

const ReportIncomeStatement = () => {

  const { user, token } = useSelector(state => state.user)
  const params = new URLSearchParams(window.location.search)
  const navigate = useNavigate()
  const advertisementRef = useRef(null)
  const labourRef = useRef(null)
  const packingRef = useRef(null)

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const formData = new FormData()
      formData.append("advertisement", advertisementRef.current ? advertisementRef.current.value : '')
      formData.append("labour", labourRef.current ? labourRef.current.value : '')
      formData.append("packing", packingRef.current ? packingRef.current.value : '')

      if (!params.has("id")) {
        const response = await dealApis.createDeal(token, formData)
        if (!response.status) throw new Error(response.message)

        advertisementRef.current.value = ""
        labourRef.current.value = ""
        packingRef.current.value = ""

      } else {
        formData.append("id", parseInt(params.get("id")))
        const response = await dealApis.updateDeal(token, formData)
        if (!response.status) throw new Error(response.message)
      }

      alert("Income Statement created")
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="category" className="my-4">
        <div className="container">
          <form method="POST" onSubmit={handleSubmit}>
            <div>
              <div className="card-header bg-white border-bottom-0 text-center">
                <h3 className="fw-bold mb-4">Income Statement Configuration</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-6 mx-auto">
                    <div className="form-floating mb-3">
                      <input ref={advertisementRef} type="number" step="0.01" className="form-control" id="advertisement" placeholder="Advertisement" required />
                      <label htmlFor="advertisement">Advertisement</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={labourRef} type="number" step="0.01" className="form-control" id="labour" placeholder="abours" required />
                      <label htmlFor="labour">Labour</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input ref={packingRef} type="number" step="0.01" className="form-control" id="packing" placeholder="packing" required />
                      <label htmlFor="packing">Packing</label>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary py-3 w-25">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    </Layout>
  )
}

export default ReportIncomeStatement