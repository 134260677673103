const ProductFilterModal = (props) => {
  const handleChange = (id) => {
    props.setProducts((previous) => {
      previous[id].checked = !previous[id].checked
      return { ...previous }
    })
  }

  const handleChangeQuantity = (id, value) => {
    props.setProducts((previous) => {
      previous[id].quantity = parseInt(value) || 1
      return { ...previous }
    })
  }

  return (
    <div className="modal fade" id="productFilterModal" tabIndex="-1" aria-labelledby="productFilterModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 fw-bold" id="productFilterModalLabel">Product Filter</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body p-0">
            <table className="table table-sm table-bordered shadow-none text-dark mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col" className="text-center">
                    <input className="form-check-input" type="checkbox" id="all" value="all" />
                  </th>
                  <th scope="col">Title</th>
                  <th scope="col">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(props.products).map((row) => {
                  return (
                    <tr key={row.toString()}>
                      <td className="align-middle text-center">
                        <input className="form-check-input" type="checkbox" id={row} value={row} onChange={() => handleChange(row)} checked={row.checked} />
                      </td>
                      <td className="align-middle">{props.products[row].title}</td>
                      <td className="align-middle p-1">
                        <input className="form-control form-control-sm shadow-none" type="number" value={props.products[row].quantity} onChange={(e) => handleChangeQuantity(row, e.target.value)} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductFilterModal
