import axios from "axios"
import { serviceUrl } from "../enumerations/constants"

export const orderProcessed = async (token, payload) => {
  const { data: response } = await axios.patch(`${serviceUrl}/dispatch/processed`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const orderUnprocessed = async (token, payload) => {
  const { data: response } = await axios.patch(`${serviceUrl}/dispatch/unprocessed`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const orderPunched = async (token, payload) => {
  const { data: response } = await axios.patch(`${serviceUrl}/dispatch/punched`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const orderAssigned = async (token, payload) => {
  const { data: response } = await axios.patch(`${serviceUrl}/dispatch/assigned`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}