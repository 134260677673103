import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as campaignApis from "../utilities/apis/campaign"
import Layout from "./Layout"

const ReadCampaign = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const messageRef = useRef()
  const [selected, setSelected] = useState([])
  const [campaign, setCampaign] = useState(null)
  const [campaignLineitems, setCampaignLineitems] = useState([])

  const handleCopy = (customer) => {
    messageRef.current.value = campaign.message
      .replace("{{name}}", customer.name || "Customer")
      .replace("{{mobile}}", customer.mobile)
      .replace("{{address}}", customer.address)
      .replace("{{last_order}}", customer.last_order)
      .replace("{{tags}}", customer.tags)
      .replace("{{zone}}", customer.zone.name)
      .replace("{{orders}}", customer.count)
      .replace("{{spent}}", customer.spent)
  }

  const handleReset = () => {
    messageRef.current.value = campaign.message
  }

  const handleSent = async () => {
    try {
      const response = await campaignApis.sentCampaign(token, selected.join(","))
      if (!response.status) throw new Error(response.message)

      alert(response.message)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleSend = async (id) => {
    try {
      const response = await campaignApis.sentCampaign(token, id.toString())
      if (!response.status) throw new Error(response.message)

      setCampaignLineitems((previous) => previous.map((item) => ({ ...item, sent: item.id === id ? true : item.sent })))
      alert(response.message)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleResponded = async () => {
    try {
      const response = await campaignApis.respondedCampaign(token, selected.join(","))
      if (!response.status) throw new Error(response.message)

      alert(response.message)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleRespond = async (id) => {
    try {
      const response = await campaignApis.respondedCampaign(token, id.toString())
      if (!response.status) throw new Error(response.message)

      setCampaignLineitems((previous) => previous.map((item) => ({ ...item, responded: item.id === id ? true : item.responded })))
      alert(response.message)
    } catch (error) {
      alert(error.message)
    }
  }


  const handleAll = (e) => {
    if (e.target.checked)
      setSelected(campaignLineitems.map((item) => item.id))
    else
      setSelected([])
  }

  const handleChange = (id) => {
    if (!selected.includes(id))
      setSelected((previous) => {
        previous.push(id)
        return [...previous]
      })
    else
      setSelected((previous) => {
        return previous.filter(x => x !== id)
      })
  }

  const params = new URLSearchParams(window.location.search)

  const getCampaign = async () => {
    try {
      const response = await campaignApis.getCampaign(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setCampaign(response.campaign)
      setCampaignLineitems(response.campaign.campaign_lineitems)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getCampaign()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="campaign" className="my-2">
        <div className="modal fade" id="helpModal" tabIndex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="helpModalLabel">Learn about placeholders</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Placeholders are the text in a message template which will be replaced with its record value.</p>
                <table className="table table-sm table-bordered shadow-none text-dark">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Placeholder</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>{`{{name}}`}</td>
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td>{`{{mobile}}`}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>{`{{address}}`}</td>
                    </tr>
                    <tr>
                      <td>Last Order</td>
                      <td>{`{{last_order}}`}</td>
                    </tr>
                    <tr>
                      <td>Tags</td>
                      <td>{`{{tags}}`}</td>
                    </tr>
                    <tr>
                      <td>Zone</td>
                      <td>{`{{zone}}`}</td>
                    </tr>
                    <tr>
                      <td>Orders</td>
                      <td>{`{{orders}}`}</td>
                    </tr>
                    <tr>
                      <td>Spent</td>
                      <td>{`{{spent}}`}</td>
                    </tr>
                  </tbody>
                </table>

                <p className="text-primary fw-bold m-0">Before</p>
                <p>Hi <span className="text-danger">{`{{name}}`}</span>, Thank you for purchasing with us. Seems like your last order was at <span className="text-danger">{`{{last_order}}`}</span>.</p>
                <p className="text-primary fw-bold m-0">After</p>
                <p>Hi <span className="text-danger">Affan Hashmi</span>, Thank you for purchasing with us. Seems like your last order was at <span className="text-danger">01-02-2023</span>.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Search</button>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="fw-bold m-0">Campaign</h3>
          </div>

          <div className="row align-items-start mb-2">
            <div className="col-md-3 pe-1">
              <label htmlFor="name" className="form-label fw-bold text-muted mb-0 me-2">Name</label>
              <input type="text" className="form-control" name="name" placeholder="Name" defaultValue={campaign?.name} />
            </div>
            <div className="col-md-9 ps-1">
              <label htmlFor="message" className="form-label fw-bold text-muted mb-0 me-2">
                Message
                <i className="far fa-question-circle text-warning ms-2" data-bs-toggle="modal" data-bs-target="#helpModal"></i>
                <i className="far fa-times-circle text-danger ms-2" onClick={handleReset}></i>
              </label>
              <textarea ref={messageRef} className="form-control" name="message" rows="4" placeholder="Hi {{name}}" defaultValue={campaign?.message}></textarea>
            </div>
          </div>
        </div>

        <table className="table table-sm table-bordered table-hover shadow-none text-dark">
          <thead className="table-light">
            <tr>
              <th scope="col" className="text-center">
                <input className="form-check-input" type="checkbox" id="all" value="all" onChange={handleAll} />
              </th>
              <th scope="col" style={{ width: "8%" }}>Name</th>
              <th scope="col" style={{ width: "8%" }}>Mobile #</th>
              <th scope="col" style={{ width: "22%" }}>Address</th>
              <th scope="col" style={{ width: "10%" }}>Last Order</th>
              <th scope="col" style={{ width: "10%" }}>Last Campaign</th>
              <th scope="col" style={{ width: "8%" }}>Tags</th>
              <th scope="col" style={{ width: "8%" }}>Zone</th>
              <th scope="col" style={{ width: "7%" }}>Orders</th>
              <th scope="col" style={{ width: "7%" }}>Spent</th>
              <th scope="col" style={{ width: "7%" }}></th>
            </tr>
          </thead>
          <tbody>
            {campaignLineitems.map((item) => {
              const row = item.customer

              return (
                <tr key={item.id.toString()}>
                  <td className="align-middle text-center">
                    <input className="form-check-input" type="checkbox" id={item.id} value={item.id} onChange={() => handleChange(item.id)} checked={selected.includes(item.id)} />
                  </td>
                  <td className="align-middle small">{row.name || "Customer"}</td>
                  <td className="align-middle small">{row.mobile}</td>
                  <td className="align-middle small">{row.address}</td>
                  <td className="align-middle small">{row.last_order && moment(row.last_order).format("DD-MM-YYYY")}</td>
                  <td className="align-middle small">{row.last_campaign && moment(row.last_campaign).format("DD-MM-YYYY")}</td>
                  <td className="align-middle small">{row.tags}</td>
                  <td className="align-middle small">{row.zone.name}</td>
                  <td className="align-middle small">{row.count}</td>
                  <td className="align-middle small">{row.spent}</td>
                  <td className="align-middle small text-center">
                    <a className="text-primary small" onClick={() => handleCopy(row)}>
                      <i className="far fa-copy"></i>
                    </a>
                    {
                      !item.sent &&
                      <a className="text-primary small ms-2" onClick={() => handleSend(item.id)}>
                        <i className="far fa-paper-plane"></i>
                      </a>
                    }
                    {
                      (item.sent && !item.responded) &&
                      <a className="text-success small ms-2" onClick={() => handleRespond(item.id)}>
                        <i className="far fa-check-circle"></i>
                      </a>
                    }
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </main>
    </Layout>
  )
}

export default ReadCampaign
