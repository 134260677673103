import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as driverApis from "../../utilities/apis/driver"
import * as orderApis from "../../utilities/apis/order"
import * as dispatcherServices from "../../utilities/services/dispatcher"
import { isDeal } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"

const Dispatch = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const driverRef = useRef(null)
  const [drivers, setDrivers] = useState([])
  const [leftItems, setLeftItems] = useState([])
  const [rightItems, setRightItems] = useState([])
  const [commission, setCommission] = useState(0)

  const pushRight = (order) => {
    setRightItems(previous => {
      previous.push(order)
      return previous
    })
    setLeftItems(previous => previous.filter(x => x.id !== order.id))
  }

  const popRight = (order) => {
    setLeftItems(previous => {
      previous.push(order)
      return previous
    })
    setRightItems(previous => previous.filter(x => x.id !== order.id))
  }

  const getDrivers = async () => {
    try {
      const response = await driverApis.getDrivers(token, {})
      if (!response.status) throw new Error(response.message)

      const activeDriver = response.drivers.filter(driver => driver.active === true)
      setDrivers(activeDriver)
    } catch (error) {
      alert(error.message)
    }
  }

  const getOrders = async () => {
    try {
      const query = new URLSearchParams({
        order_status: "punched"
      })
      const response = await orderApis.getOrders(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setLeftItems(response.orders)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleChange = (e) => {
    if (e.target.selectedIndex > 0)
      setCommission(drivers[e.target.selectedIndex - 1].commission)
    else
      setCommission(0)
  }

  const handleSubmit = async () => {
    try {
      if (!driverRef.current.value) return alert("Please select a driver")
      if (rightItems.length === 0) return alert("Please move atleast 1 order to the right")

      const payload = rightItems.map((item) => {
        return {
          order_id: item.id,
          customer_id: item.customer_id,
          driver_id: driverRef.current.value,
          commission: commission
        }
      })

      const response = await dispatcherServices.orderAssigned(token, payload)
      if (!response.status) throw new Error(response.message)

      alert("Order assigned")
      driverRef.current.value = ""
      setRightItems(() => [])
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getDrivers()
    getOrders()
  }, [])

  const total = leftItems.reduce((previous, current) => previous += current.total - current.discount, 0)
  const totalCommission = commission > 0 ? rightItems.reduce((previous, current) => previous += current.total < 1000 ? 150 : commission, 0) : 0

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order" className="flex-column">
        <div className="row align-items-center p-2">
          <div className="col-4">
            <h3 className="fw-bold m-0">Orders <small>({leftItems.length}) - Rs. {total.toFixed(2)}</small></h3>
          </div>
          <div className="col-4 text-center">
            <button className="btn btn-primary w-50" onClick={handleSubmit}>Assign to Rider</button>
          </div>
          <div className="col-4">
            <div className="row align-items-center">
              <div className="col-6 text-end">
                <h5 className="fw-bold m-0">Orders <small>({rightItems.length}) - Rs. {totalCommission.toFixed(2)} </small></h5>
              </div>
              <div className="col-6">
                <select ref={driverRef} onChange={handleChange} className="form-select w-100">
                  <option key="0" value="">Select Rider</option>
                  {drivers.map((driver) => (
                    <option key={driver.id.toString()} value={driver.id}>{driver.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow-1 d-flex overflow-y-auto border-top">
          <section className="flex-grow-1 border-end">
            <div className="accordion accordion-flush">
              {leftItems.map((order, index) => {
                return (
                  <div key={`left-${order.id}`} className="d-flex">
                    <div className="accordion-item flex-grow-1">
                      <h2 className="accordion-header" id={`heading-left-${index}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-left-${index}`} aria-expanded="true" aria-controls={`collapse-left-${index}`}>
                          <span className="fw-bold me-1">ORDER #:</span><span className="me-3">P{order.id}</span> |
                          <span className="fw-bold ms-3 me-1">ZONE:</span><span className="me-3">{order.zone.name}</span> |
                          <span className="ms-3 me-1">{moment(order.ship_datetime).format("DD-MM-YY HH:mm")}</span>
                        </button>
                      </h2>
                      <div id={`collapse-left-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-left-${index}`} data-bs-parent="#accordion-left">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-4">
                              <h5>Customer</h5>
                              <p className="m-0">{order.customer.name}</p>
                              <p className="m-0">{order.customer.mobile}</p>
                            </div>
                            <div className="col-8">
                              <h5>Order Lineitems</h5>
                              <ul className="list-unstyled">
                                {order.order_lineitems.map((item, index) => {
                                  if (isDeal(item.type))
                                    return (
                                      <li key={`left-${index}`}>{item.title} x{item.quantity} = {(item.quantity * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}</li>
                                    )
                                  else
                                    return (
                                      <li key={`left-${index}`}>{item.title} x{item.quantity} = {(item.quantity * item.price).toFixed(2)}</li>
                                    )
                                })}
                                <li key="left-total">Total: {(order.total - order.discount).toFixed(2)}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-primary rounded-0 shadow-none" onClick={() => pushRight(order)}><i className="fas fa-chevron-right"></i></button>
                  </div>
                )
              })}
            </div>
          </section>
          <aside className="flex-grow-1 border-start">
            <div className="accordion accordion-flush" id="accordion-right">
              {rightItems.map((order, index) => {
                return (
                  <div key={`right-${order.id}`} className="d-flex">
                    <button className="btn btn-primary rounded-0 shadow-none" onClick={() => popRight(order)}><i className="fas fa-chevron-left"></i></button>
                    <div className="accordion-item flex-grow-1">
                      <h2 className="accordion-header" id={`heading-right-${index}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-right-${index}`} aria-expanded="true" aria-controls={`collapse-right-${index}`}>
                          <span className="fw-bold me-1">ORDER #:</span><span className="me-3">P{order.id}</span> |
                          <span className="fw-bold ms-3 me-1">ZONE:</span><span className="me-3">{order.zone.name}</span> |
                          <span className="ms-3 me-1">{moment(order.ship_datetime).format("DD-MM-YY HH:mm")}</span>
                        </button>
                      </h2>
                      <div id={`collapse-right-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-right-${index}`} data-bs-parent="#accordion-right">
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-4">
                              <h5>Customer</h5>
                              <p className="m-0">{order.customer.name}</p>
                              <p className="m-0">{order.customer.mobile}</p>
                            </div>
                            <div className="col-8">
                              <h5>Order Lineitems</h5>
                              <ul className="list-unstyled">
                                {order.order_lineitems.map((item, index) => {
                                  if (isDeal(item.type))
                                    return (
                                      <li key={`right-${index}`}>{item.title} x{item.quantity} = {(item.quantity * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}</li>
                                    )
                                  else
                                    return (
                                      <li key={`right-${index}`}>{item.title} x{item.quantity} = {(item.quantity * item.price).toFixed(2)}</li>
                                    )
                                })}
                                <li key="right-total">Total: {(order.total - order.discount).toFixed(2)}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </aside>
        </div>
      </main>
    </Layout>
  )
}

export default Dispatch
