import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as orderApi from "../../utilities/apis/order_history"
import Layout from "../../components/Layout"
import Pagination from "../../components/Pagination"

const OrderHistory = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const formRef = useRef(null)
  const [orderHistories, setOrderHistories] = useState([])
  const mobileRef = useRef(null)
  const [active, setActive] = useState(1)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  })

  const handleView = (id) => {
    navigate(`/dashboard/order_request?id=${id}`)
  }

  const handleSearch = () => {
    const where = {}

    if (formRef.current.id.value) where.id = formRef.current.id.value.replace(/p/gi, "")
    if (mobileRef.current.value) where.customer = mobileRef.current.value

    setFilters({ ...filters, ...where })
  }

  const handleReset = () => {
    setFilters({limit: 10, offset: 0})
  }

  const getOrderHistories = async () => {
    try {
      const query = new URLSearchParams(filters)
      const response = await orderApi.getOrderHistories(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setCount(response.count)
      setOrderHistories(response.order_histories)
    } catch (error) {
      alert(error.message)
    }
  }

  const handlePageChange = (page, offset) => {
    setFilters({ ...filters, offset })
    setActive(page)
  }

  useEffect(() => {
    if (!user) navigate("/")

    getOrderHistories()
  }, [])

  // useEffect(() => {
  //   setRows(orderHistories.slice((active - 1) * limit, active * limit))
  // }, [orderHistories.length])

  useEffect(() => {
    getOrderHistories(filters)
  }, [filters])

  // const pages = new Array(Math.ceil(count / limit)).fill(null).map((_, index) => index + 1)

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="orders" className="my-2">
        <form ref={formRef}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="fw-bold m-0">Order History</h3>
                </div>
              </div>
                <div className="d-flex mb-3 col-3 gap-2">
                  <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile #" required autoFocus />
                  <button type="button" className="btn btn-sm btn-light text-primary" onClick={handleSearch}><i className="fas fa-search"></i></button>
                  <button type="reset" className="btn btn-sm btn-light text-danger" onClick={handleReset}><i className="fas fa-times"></i></button>
                </div>
            </div>
          </div>
          <table className="table table-bordered table-hover shadow-none text-dark">
            <thead className="table-light">
              <tr>
                <th scope="col" style={{ width: "10%" }} className="align-middle text-center">S. No.</th>
                <th scope="col" style={{ width: "25%" }} className="align-middle">Customer</th>
                <th scope="col" style={{ width: "25%" }} className="align-middle">Product</th>
                <th scope="col" style={{ width: "15%" }} className="align-middle">Quantity</th>
                <th scope="col" style={{ width: "25%" }} className="align-middle">Last Order</th>
              </tr>
            </thead>
            <tbody>
              {orderHistories.map((row) => {
                return (
                  <tr key={row.id.toString()}>
                    <td className="align-middle text-center">{row.id}</td>
                    <td className="align-middle">{row.customer.mobile}</td>
                    <td className="align-middle">{row.product.title}</td>
                    <td className="align-middle">{row.quantity}</td>
                    <td className="align-middle">{moment(row.last_order).format("DD-MM-YYYY")}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <div className="container-fluid">
            <nav aria-label="pagination">
              <Pagination
                currentPage={active}
                totalCount={count}
                pageSize={filters.limit}
                offset={filters.offset}
                siblingCount={3}
                onPageChange={handlePageChange}
              />
            </nav>
          </div>
        </form>
      </main>
    </Layout>
  )
}

export default OrderHistory
