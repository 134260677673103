import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as orderRequestApis from "../../utilities/apis/order_request"
import * as requestApis from "../../utilities/apis/order_request"
import { getRequestStatus } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"

const OrderRequest = () => {
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)
  const params = new URLSearchParams(window.location.search)
  const [orderRequest, setOrderRequest] = useState(null)

  const getOrderRequest = async () => {
    try {
      const response = await orderRequestApis.getOrderRequest(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setOrderRequest(response.order_request)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleComplete = async () => {
    try {
      const payload = {
        id: orderRequest.id,
        order_id: JSON.parse(orderRequest.order_id)
      }
      const response = await orderRequestApis.completeOrderRequest(token, payload)
      if (!response.status) throw new Error(response.message)

      setOrderRequest({
        ...orderRequest,
        status: "completed"
      })

      alert("Orders present in this request are marked as complete")
    } catch (error) {
      alert(error.message)
    }
  }

  const handleReject = async () => {
    try {
      const payload = {
        id: orderRequest.id,
        order_id: JSON.parse(orderRequest.order_id)
      }
      const response = await requestApis.rejectOrderRequest(token, payload)
      if (!response.status) throw new Error(response.message)

      setOrderRequest({
        ...orderRequest,
        status: "rejected"
      })

      alert("Selected request have been marked as rejected")
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    if (params.has("id"))
      getOrderRequest()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order" className="py-4">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h3 className="fw-bold m-0">Order Request Details</h3>
            <div className="d-flex gap-2">
              {(permissions["COMPLETE_ORDER_REQUEST"]) && orderRequest && orderRequest?.status === "requested" && <button className="btn btn-success" onClick={() => handleComplete()}>Complete</button>}
              {(permissions["REJECT_ORDER_REQUEST"]) && orderRequest && orderRequest?.status === "requested" && <button className="btn btn-danger" onClick={() => handleReject()}>Reject</button>}
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="fw-bold m-0">REQ #</p>
                      <p>{orderRequest && `PREQ${orderRequest.id}`}</p>

                      <p className="fw-bold m-0">Order #</p>
                      <p>{orderRequest && <a href={`order?id=${orderRequest.order_id}`}>P{orderRequest.order_id}</a>}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="fw-bold m-0">Note</p>
                      <p>{orderRequest && orderRequest.note}</p>

                      <p className="fw-bold m-0">Status</p>
                      <p>{orderRequest && getRequestStatus[orderRequest.status]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <div className="card-header bg-white border-bottom-0">
                  <p className="m-0 text-dark fw-bold lead">Rider</p>
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className={`driver-icon bg-${orderRequest?.driver?.name ? "warning" : "danger"}-light me-3`}>
                      <i className={`bi bi-truck text-${orderRequest?.driver?.name ? "warning" : "danger"}`}></i>
                    </div>
                    <div>
                      <p className={`m-0 text-${orderRequest?.driver?.name ? "warning" : "danger"}`}>{orderRequest?.driver?.name || 'Not assigned'}</p>
                      <p className="m-0 small">{orderRequest?.driver?.username}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default OrderRequest
