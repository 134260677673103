import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as categoryApis from "../../utilities/apis/category"
import Layout from "../../components/Layout"

const Categories = () => {
  const navigate = useNavigate()
  const { token, user, permissions } = useSelector(state => state.user)
  const [categories, setCategories] = useState([])

  const handleNew = () => {
    navigate("/dashboard/category")
  }

  const handleEdit = (id) => {
    navigate(`/dashboard/category?id=${id}`)
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await categoryApis.deleteCategory(token, id)
      if (!response.status) throw new Error(response.message)

      setCategories((previous) => {
        const categories = previous.filter(x => x.id !== id)
        return [...categories]
      })
    } catch (error) {
      alert(error.message)
    }
  }

  const getCategories = async () => {
    try {
      const response = await categoryApis.getCategories(token, "")
      if (!response.status) throw new Error(response.message)

      setCategories(response.categories)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getCategories()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main className="tabular my-2">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="fw-bold m-0">Categories</h3>
            {(permissions["CREATE_CATEGORY"]) && (<button className="btn btn-primary" onClick={() => handleNew()}>New</button>)}
          </div>
        </div>

        <table className="table table-bordered table-hover shadow-none">
          <thead className="table-light">
            <tr>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">ID</th>
              <th scope="col" style={{ width: "80%" }} className="align-middle">Name</th>
              <th scope="col" style={{ width: "10%" }} className="align-middle text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((row) => (
              <tr key={row.id.toString()}>
                <td className="align-middle text-center">{row.id}</td>
                <td className="align-middle">{row.name}</td>
                <td className="align-middle text-center">
                {(permissions["UPDATE_CATEGORY"]) && (<a className="me-2" onClick={() => handleEdit(row.id)}><i className="far fa-edit"></i></a>)}
                {(permissions["DELETE_CATEGORY"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </Layout>
  )
}

export default Categories
