import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import * as dealApis from "../utilities/apis/deal"
import * as orderApis from "../utilities/apis/order"
import { url } from "../utilities/enumerations/constants"
import GuestLayout from "../components/GuestLayout"

const Deal = () => {
  const navigate = useNavigate()
  const userState = useSelector((state) => state.user)
  const { slug } = useParams()
  const mobileRef = useRef(null)
  const addressRef = useRef(null)
  const dateRef = useRef(null)
  const timeRef = useRef(null)
  const noteRef = useRef(null)
  const defaultDate = moment().add(2, 'days').format('YYYY-MM-DD')
  const defaultTime = "12:40"
  const [deal, setDeal] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState({})

  const calculateTotalPrice = () => {
    const totalSelectedPrice = deal?.products.reduce((total, product) => {
      const selectedProduct = selectedProducts[deal.id]?.[product.id]
      const productPrice = selectedProduct ? selectedProduct.price : product.price
      return total + productPrice
    }, 0)

    return totalSelectedPrice || deal?.price
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const ship_datetime = moment(`${dateRef.current.value} ${timeRef.current.value}`)
      const dealData = deal
      const lineitems = dealData.products.map((product) => {
        const selectedProduct = selectedProducts[deal.id]?.[product.id]

        return {
          cost: selectedProduct ? selectedProduct.cost : product.cost,
          price: selectedProduct ? selectedProduct.price : product.price,
          quantity: product.order_quantity,
          deal_cost: dealData.cost,
          deal_price: calculateTotalPrice(),
          deal_discount: dealData.discount,
          deal_quantity: product.order_quantity,
          swap: 0,
          product_id: selectedProduct ? selectedProduct.id : product.id,
          deal_id: dealData.id,
        }
      })
      const payload = {
        zone: {
          name: "N/A"
        },
        customer: {
          mobile: mobileRef.current.value,
          address: addressRef.current.value
        },
        order: {
          discount: deal.discount,
          customer_note: noteRef.current.value,
          ship_datetime: ship_datetime.format("YYYY-MM-DD HH:mm"),
          total: (calculateTotalPrice()),
          cost: lineitems.reduce((totalCost, item) => totalCost + item.quantity * item.cost, 0),
          commission: 0,
        },
        order_lineitems: lineitems
      }
      const response = await orderApis.checkout(userState.token, payload)
      if (response.status) {
        alert("Order created successfully!")
        navigate("/")
      } else {
        throw new Error(response.message)
      }
    } catch (error) {
      alert("Error creating order: " + error.message)
    }
  }

  const getDeal = async () => {
    try {
      const response = await dealApis.getHomeDeal(slug)
      if (!response.status) throw new Error(response.message)

      setDeal(response.deal)
    } catch (error) {
      alert(error.message)
    }
  }

  const handleChange = (e, itemId, productId) => {
    const updatedValue = e.target.value
    const selectedProduct = deal.products.find((product) => product.id === parseInt(updatedValue))

    setSelectedProducts((prevSelected) => ({
      ...prevSelected,
      [itemId]: {
        ...prevSelected[itemId],
        [productId]: selectedProduct
          ? { id: selectedProduct.id, title: selectedProduct.title, price: selectedProduct.price }
          : null,
      },
    }))
  }

  useEffect(() => {
    if (slug) getDeal()
    dateRef.current.value = defaultDate
    timeRef.current.value = defaultTime
  }, [slug])

  return (
    <GuestLayout
      header={true}
      footer={true}
    >
      <div className="container py-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="fw-bold m-0">Deal Details</h3>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <img src={deal?.thumbnail_url?.replace("public", url) ?? deal?.thumbnail} alt={deal?.title} className="img-fluid rounded" />
                  </div>
                  <div className="col-md-9">
                    <h5>Title</h5>
                    <p>{deal?.title}</p>

                    <h5>Description</h5>
                    <p>{deal?.description}</p>

                    <div className="row">
                      <div className="col-4">
                        <h5>Price</h5>
                        <p>Rs. {(calculateTotalPrice() ?? 0).toFixed(2)}</p>
                      </div>
                      <div className="col-4">
                        <h5>Discount</h5>
                        <p>Rs. {deal?.discount.toFixed(2)}</p>
                      </div>
                      <div className="col-4">
                        <h5>Offer</h5>
                        <p>Rs. {(calculateTotalPrice() - deal?.discount).toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card my-4">
              <div className="card-body p-0">
                <table className="table table-sm mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "50%" }} className="fw-bold">Products</th>
                      <th scope="col" style={{ width: "25%" }} className="text-center fw-bold">Price</th>
                      <th scope="col" style={{ width: "25%" }} className="text-center fw-bold">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deal?.products.map((product, index) => (
                      <tr key={index}>
                        {/* <td>
                          <select
                            className="form-select w-100"
                            onChange={(e) => handleChange(e, deal.id, product.id)}
                            value={selectedProducts[deal.id]?.[product.id]?.id || ""}
                            >
                            <option value="">{product.title}</option>
                            {deal.products.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.title}
                              </option>
                            ))}
                          </select>
                        </td> */}
                        <td className="align-middle">{product.title}</td>
                        <td className="align-middle text-center">Rs. {selectedProducts[deal.id]?.[product.id]?.price.toFixed(2) || product.price.toFixed(2)}</td>
                        <td className="align-middle text-center">{product.order_quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {deal && deal.video_url && <div className="card">
              <div className="card">
                <iframe src={deal.video_url} className="video"></iframe>
              </div>
            </div>}
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-floating mb-2">
                    <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile" required />
                    <label htmlFor="mobile">Mobile No</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input ref={addressRef} type="text" className="form-control" id="address" placeholder="Address" required />
                    <label htmlFor="address">Address</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input ref={dateRef} type="date" className="form-control" id="date" placeholder="Ship Date" required />
                    <label htmlFor="date">Ship Date</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input ref={timeRef} type="time" className="form-control" id="time" placeholder="Ship Time" required />
                    <label htmlFor="time">Ship Time</label>
                  </div>
                  <div className="form-floating mb-2">
                    <textarea ref={noteRef} className="form-control" id="note" placeholder="Note"></textarea>
                    <label htmlFor="note">Note</label>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <h5>Total:</h5>
                    <p>Rs. {(calculateTotalPrice() - deal?.discount).toFixed(2)}</p>
                  </div>
                  <p className="text-danger fst-italic">* Please note if you want to change the products according to your need you can mention it in the notes, additional charges may apply</p>
                  <p className="text-danger text-end fst-italic">اگر آپ اپنی ضرورت کے مطابق مصنوعات کو تبدیل کرنا چاہتے ہیں تو آپ اس کا ذکر نوٹ میں کرسکتے ہیں، اضافی چارجز لاگو ہوسکتے ہیں</p>
                  <hr />
                  <button type="submit" className="btn btn-primary form-control">Place Order</button>
                </form>
                {deal && deal.whatsapp_url && <a style={{ textDecoration: 'none', color: 'white' }} href="https://api.whatsapp.com/send?phone=03146408920"><button className="btn btn-success form-control mt-2"><i className="fab fa-whatsapp"></i> Whatsapp </button></a>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}

export default Deal
