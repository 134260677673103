import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Layout from "../../components/Layout"
import * as reportService from "../../utilities/services/report"

const Insights = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const [type, setType] = useState("daily")

  const [previousTopProduct, setPreviousTopProduct] = useState({})
  const [currentTopProduct, setCurrentTopProduct] = useState({})

  const [previousTopDeal, setPreviousTopDeal] = useState({})
  const [currentTopDeal, setCurrentTopDeal] = useState({})

  const getTopProduct = async () => {
    try {
      const previousQuery = new URLSearchParams({ by: "previous", type })
      const previousResponse = await reportService.getTopProduct(token, previousQuery.toString())
      if (!previousResponse.status) throw new Error(previousResponse.message)

      const currentQuery = new URLSearchParams({ by: "current", type })
      const currentResponse = await reportService.getTopProduct(token, currentQuery.toString())
      if (!currentResponse.status) throw new Error(currentResponse.message)

      setPreviousTopProduct(previousResponse.product)
      setCurrentTopProduct(currentResponse.product)
    } catch (error) {
      alert(error.message)
    }
  }

  const getTopDeal = async () => {
    try {
      const previousQuery = new URLSearchParams({ by: "previous", type })
      const previousResponse = await reportService.getTopDeal(token, previousQuery.toString())
      if (!previousResponse.status) throw new Error(previousResponse.message)

      const currentQuery = new URLSearchParams({ by: "current", type })
      const currentResponse = await reportService.getTopDeal(token, currentQuery.toString())
      if (!currentResponse.status) throw new Error(currentResponse.message)

      setPreviousTopDeal(previousResponse.deal)
      setCurrentTopDeal(currentResponse.deal)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")
  }, [])

  useEffect(() => {
    getTopProduct()
    getTopDeal()
  }, [type])

  let previousTitle = ""
  let currentTitle = ""
  switch (type) {
    case "daily":
      previousTitle = "Yesterday"
      currentTitle = "Today"
      break
    case "weekly":
      previousTitle = "Last Week"
      currentTitle = "This Week"
      break
    case "monthly":
      previousTitle = "Last Month"
      currentTitle = "This Month"
      break
    case "yearly":
      previousTitle = "Last Year"
      currentTitle = "This Year"
      break
    default:
      previousTitle = "Yesterday"
      currentTitle = "Today"
      break
  }

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-2 text-dark">
        <div className="container-fluid">
          <div className="row align-items-center mb-2">
            <div className="col-10 pe-1">
              <h3 className="fw-bold m-0">Insights</h3>
            </div>
            <div className="col-2 ps-1">
              <select className="form-select" onChange={(e) => setType(e.target.value)}>
                <option value="daily">Daily</option>
                {/* <option value="weekly">Weekly</option> */}
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
          </div>

          <div className="row g-0 shadow my-2">
            <div className="col-3">
              <div className="card rounded-0 rounded-start shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="small text-muted m-0">{previousTitle}</p>
                      <p className="lead fw-bold m-0">Top Product</p>
                    </div>
                    <p className="lead text-primary fw-bold m-0">{previousTopProduct?.title}</p>
                  </div>
                  <br /><br />
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="text-end fw-bold m-0">{previousTopProduct?.count}</h3>
                    <h3 className="text-end fw-bold m-0">{previousTopProduct?.value && previousTopProduct.value.toFixed(2)}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card rounded-0 shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="small text-muted m-0">{currentTitle}</p>
                      <p className="lead fw-bold m-0">Top Product</p>
                    </div>
                    <p className="lead text-primary fw-bold m-0">{currentTopProduct?.title}</p>
                  </div>
                  <br /><br />
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="text-end fw-bold m-0">{currentTopProduct?.count}</h3>
                    <h3 className="text-end fw-bold m-0">{currentTopProduct?.value && currentTopProduct?.value.toFixed(2)}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card rounded-0 rounded-start shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="small text-muted m-0">{previousTitle}</p>
                      <p className="lead fw-bold m-0">Top Deal</p>
                    </div>
                    <p className="lead text-primary fw-bold m-0">{previousTopDeal?.title}</p>
                  </div>
                  <br /><br />
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="text-end fw-bold m-0">{previousTopDeal?.count}</h3>
                    <h3 className="text-end fw-bold m-0">{previousTopDeal?.value && previousTopDeal.value.toFixed(2)}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card rounded-0 shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="small text-muted m-0">{currentTitle}</p>
                      <p className="lead fw-bold m-0">Top Deal</p>
                    </div>
                    <p className="lead text-primary fw-bold m-0">{currentTopDeal?.title}</p>
                  </div>
                  <br /><br />
                  <div className="d-flex justify-content-between align-items-center">
                    <h3 className="text-end fw-bold m-0">{currentTopDeal?.count}</h3>
                    <h3 className="text-end fw-bold m-0">{currentTopDeal?.value && currentTopDeal?.value.toFixed(2)}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Insights
