import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import Layout from "../../components/Layout"
import * as reportService from "../../utilities/services/report"

const ReportSummary = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const [summary, setSummary] = useState([])
  const [orders, setOrders] = useState([])
  const [type, setType] = useState("daily")
  const [ratio, setRatio] = useState(0)
  const [ordersZone, setOrdersZone] = useState([])
  const [ordersDriver, setOrdersDriver] = useState([])
  const [ordersActive, setOrdersActive] = useState([])

  const getSummary = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getSummary(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setSummary(response.orders)
    } catch (error) {
      alert(error.message)
    }
  }

  const getOrders = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getOrders(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrders(response.orders)
    } catch (error) {
      alert(error.message)
    }
  }

  const getRatio = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getRatio(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setRatio(response.ratio)
    } catch (error) {
      alert(error.message)
    }
  }

  const getOrdersByZone = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getOrdersByZone(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrdersZone(response.orders)
    } catch (error) {
      alert(error.message)
    }
  }

  const getOrdersByDriver = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getOrdersByDriver(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrdersDriver(response.orders)
    } catch (error) {
      alert(error.message)
    }
  }

  const getOrdersByActiveDrivers = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getOrdersByActiveDrivers(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrdersActive(response.orders)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getOrdersByZone({})
    getOrdersByDriver({})
    getOrdersByActiveDrivers({})
  }, [])

  useEffect(() => {
    getSummary({ type })
    getOrders({ type })
    getRatio({})
  }, [type])

  let subtract = ""
  let format = ""
  let previousTitle = ""
  let currentTitle = ""
  switch (type) {
    case "daily":
      subtract = "day"
      format = "YYYY-MM-DD"
      previousTitle = "Yesterday"
      currentTitle = "Today"
      break
    case "weekly":
      subtract = "week"
      format = "WW"
      previousTitle = "Last Week"
      currentTitle = "This Week"
      break
    case "monthly":
      subtract = "month"
      format = "YYYY-MM"
      previousTitle = "Last Month"
      currentTitle = "This Month"
      break
    case "yearly":
      subtract = "year"
      format = "YYYY"
      previousTitle = "Last Year"
      currentTitle = "This Year"
      break
    default:
      subtract = "day"
      format = "YYYY-MM-DD"
      previousTitle = "Yesterday"
      currentTitle = "Today"
      break
  }

  const yesterday = summary.find(order => order.by === moment().subtract(1, subtract).format(format))
  const yesterdayValue = (yesterday ? yesterday.value : 0).toFixed(2)
  const yesterdayBasket = (yesterday ? yesterday.value / yesterday.count : 0).toFixed(2)
  const today = summary.find(order => order.by === moment().format(format))
  const todayValue = (today ? today.value : 0).toFixed(2)
  const todayBasket = (today ? today.value / today.count : 0).toFixed(2)

  const yesterdayItem = orders.find(order => order.by === moment().subtract(1, subtract).format(format))
  const yesterdayItemsCount = (yesterdayItem ? yesterdayItem.count : 0)
  const todayItem = orders.find(order => order.by === moment().format(format))
  const todayItemsCount = (todayItem ? todayItem.count : 0)

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-2 text-dark">
        <div className="container-fluid">
          <div className="row align-items-center mb-2">
            <div className="col-10 pe-1">
              <h3 className="fw-bold m-0">Sale Summary</h3>
            </div>
            <div className="col-2 ps-1">
              <select className="form-select" onChange={(e) => setType(e.target.value)}>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </div>
          </div>

          <div className="row g-0 shadow my-2">
            <div className="col-3">
              <div className="card rounded-0 rounded-start shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="small text-muted m-0">{previousTitle}</p>
                      <p className="lead fw-bold m-0">Sale</p>
                    </div>
                    <h1 className="text-primary fw-bold m-0">{yesterday?.count}</h1>
                  </div>
                  <br /><br />
                  <h3 className="text-end fw-bold m-0">{yesterdayValue}</h3>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card rounded-0 shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="small text-muted m-0">{currentTitle}</p>
                      <p className="lead fw-bold m-0">Sale</p>
                    </div>
                    <h1 className="text-primary fw-bold m-0">{today?.count}</h1>
                  </div>
                  <br /><br />
                  <div className="d-flex justify-content-between align-items-center">
                    <span className={`badge rounded-pill ${todayValue - yesterdayValue > 0 ? "badge-success-light text-success" : "badge-danger-light text-danger"}`}><i className={`fas fa-arrow-circle-${todayValue - yesterdayValue > 0 ? "up" : "down"} me-1`}></i> {Math.abs(todayValue - yesterdayValue).toFixed(2)}</span>
                    <h3 className="text-end fw-bold m-0">{todayValue}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card rounded-0 shadow-none h-100">
                <div className="card-body">
                  <p className="small text-muted m-0">{previousTitle}</p>
                  <p className="lead fw-bold m-0">Average Basket Value</p>
                  <br /><br />
                  <h3 className="text-end fw-bold m-0">{yesterdayBasket}</h3>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card rounded-0 rounded-end shadow-none h-100">
                <div className="card-body">
                  <p className="small text-muted m-0">{currentTitle}</p>
                  <p className="lead fw-bold m-0">Average Basket Value</p>
                  <br /><br />
                  <div className="d-flex justify-content-between align-items-center">
                    <span className={`badge rounded-pill ${todayBasket - yesterdayBasket > 0 ? "badge-success-light text-success" : "badge-danger-light text-danger"}`}><i className={`fas fa-arrow-circle-${todayBasket - yesterdayBasket > 0 ? "up" : "down"} me-1`}></i> {Math.abs(todayBasket - yesterdayBasket).toFixed(2)}</span>
                    <h3 className="text-end fw-bold m-0">{todayBasket}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0 my-2">
            <div className="col-3">
              <div className="card rounded-0 rounded-start shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="small text-muted m-0">{previousTitle}</p>
                      <p className="lead fw-bold m-0">No. of Items Sold</p>
                    </div>
                    {/* <h1 className="text-primary fw-bold m-0">{today?.count}</h1> */}
                  </div>
                  <br /><br />
                  <div className="d-flex justify-content-end align-items-center">
                    {/* <span className={`badge rounded-pill ${todayValue - yesterdayValue > 0 ? "badge-success-light text-success" : "badge-danger-light text-danger"}`}><i className={`fas fa-arrow-circle-${todayValue - yesterdayValue > 0 ? "up" : "down"} me-1`}></i> {Math.abs(todayValue - yesterdayValue).toFixed(2)}</span> */}
                    <h3 className="text-end fw-bold m-0">{yesterdayItemsCount}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card rounded-0 shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="small text-muted m-0">{currentTitle}</p>
                      <p className="lead fw-bold m-0">No. of Items Sold</p>
                    </div>
                    {/* <h1 className="text-primary fw-bold m-0">{today?.count}</h1> */}
                  </div>
                  <br /><br />
                  <div className="d-flex justify-content-end align-items-center">
                    {/* <span className={`badge rounded-pill ${todayValue - yesterdayValue > 0 ? "badge-success-light text-success" : "badge-danger-light text-danger"}`}><i className={`fas fa-arrow-circle-${todayValue - yesterdayValue > 0 ? "up" : "down"} me-1`}></i> {Math.abs(todayValue - yesterdayValue).toFixed(2)}</span> */}
                    <h3 className="text-end fw-bold m-0">{todayItemsCount}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card rounded-0 rounded-end shadow-none h-100">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <p className="small text-muted m-0">Average</p>
                      <p className="lead fw-bold m-0">Repeating Orders</p>
                    </div>
                  </div>
                  <br /><br />
                  <h3 className="text-end fw-bold m-0">{ratio}</h3>
                </div>
              </div>
            </div>
            <div className="col-3">
            </div>
          </div>

          <div className="row g-0 my-2">
            <div className="col-4 pe-1">
              <div className="card h-100 summary-table">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <p className="lead fw-bold m-0">Remaining Orders ({ordersZone.reduce((p, c) => p += parseInt(c.orders), 0)})</p>
                  <p className="small text-muted m-0">Except Delivered</p>
                </div>
                <div className="card-body p-0">
                  <table className="table table-sm table-hover shadow-none text-dark mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70%" }} className="align-middle">Zone</th>
                        <th scope="col" style={{ width: "30%" }} className="align-middle text-center">Orders</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersZone.map((order) => {
                        return (
                          <tr key={order.zone}>
                            <td className="align-middle">{order.zone}</td>
                            <td className="align-middle text-center lead fw-bold">{order.orders}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-4 px-1">
              <div className="card h-100 summary-table">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <p className="lead fw-bold m-0">Remaining Payments ({ordersDriver.reduce((p, c) => p += parseInt(c.orders), 0)})</p>
                  <p className="small text-muted m-0">Delivered/Requested/Unpaid</p>
                </div>
                <div className="card-body p-0">
                  <table className="table table-sm table-hover shadow-none text-dark mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "40%" }} className="align-middle">Driver</th>
                        <th scope="col" style={{ width: "40%" }} className="align-middle text-center">Amount</th>
                        <th scope="col" style={{ width: "20%" }} className="align-middle text-center">Orders</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersDriver.map((order) => {
                        return (
                          <tr key={order.driver}>
                            <td className="align-middle">{order.driver}</td>
                            <td className="align-middle text-center">Rs. {order.value.toFixed(2)}</td>
                            <td className="align-middle text-center lead fw-bold">{order.orders}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-4 ps-1">
              <div className="card h-100 summary-table">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <p className="lead fw-bold m-0">Active Riders ({ordersActive.reduce((p, c) => p += parseInt(c.orders), 0)})</p>
                  <p className="small text-muted m-0">Last 15 days</p>
                </div>
                <div className="card-body p-0">
                  <table className="table table-sm table-hover shadow-none text-dark mb-0">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "40%" }} className="align-middle">Driver</th>
                        <th scope="col" style={{ width: "20%" }} className="align-middle text-center">Amount</th>
                        <th scope="col" style={{ width: "20%" }} className="align-middle text-center">Earned</th>
                        <th scope="col" style={{ width: "20%" }} className="align-middle text-center">Orders</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersActive.map((order) => {
                        return (
                          <tr key={order.driver}>
                            <td className="align-middle">{order.driver}</td>
                            <td className="align-middle text-center">Rs. {order.value.toFixed(2)}</td>
                            <td className="align-middle text-center">Rs. {order.commission.toFixed(2)}</td>
                            <td className="align-middle text-center lead fw-bold">{order.orders}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default ReportSummary
