import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as requestApis from "../../utilities/apis/request"
import { getRequestStatus, isDeal } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"

const Request = () => {
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)
  const params = new URLSearchParams(window.location.search)
  const [request, setRequest] = useState(null)

  const handlePay = async () => {
    try {
      const payload = {
        id: request.id,
        order_ids: JSON.parse(request.order_ids)
      }
      const response = await requestApis.completeRequest(token, payload)
      if (!response.status) throw new Error(response.message)

      setRequest({
        ...request,
        status: "completed"
      })
      
      alert("Orders present in this request are marked as paid")
    } catch (error) {
      alert(error.message)
    }
  }

  const handleView = (id) => {
    window.open(`/dashboard/order?id=${id}`);
  }

  const getOrder = async () => {
    try {
      const response = await requestApis.getRequest(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setRequest(response.request)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    if (params.has("id"))
      getOrder()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order" className="py-4">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h3 className="fw-bold m-0">Request Details ({request?.orders?.length})</h3>
            {(permissions["COMPLETE_REQUEST"]) && request?.status !== "completed" && <button className="btn btn-success" onClick={() => handlePay()}>Mark as Paid</button>}
          </div>

          <div className="row">
            <div className="col-md-9">
              <div className="card">
                <div className="card-body">
                  <table className="table shadow-none">
                    <thead>
                      <tr>
                        <th scope="col" className="fw-bold">Order #</th>
                        <th scope="col" className="text-center fw-bold">Discount</th>
                        <th scope="col" className="text-center fw-bold">Total</th>
                        <th scope="col" className="text-center fw-bold">Commission</th>
                        <th scope="col" className="text-center fw-bold">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {request?.orders?.map((item) => {
                        return (
                          <tr key={item.id.toString()}>
                            <td className="align-middle">P{item.id}</td>
                            <td className="align-middle text-center">Rs. {(item.discount || 0).toFixed(2)}</td>
                            <td className="align-middle text-center">Rs. {(item.total - (item.discount || 0)).toFixed(2)}</td>
                            <td className="align-middle text-center">Rs. {((item.total - (item.discount || 0)) < 1000 ? 150 : item.commission).toFixed(2)}</td>
                            <td className="align-middle text-center">
                              <a className="me-2" onClick={() => handleView(item.id)}><i className="far fa-clipboard"></i></a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mb-4">
                <div className="card-header bg-white border-bottom-0">
                  <p className="m-0 text-dark fw-bold lead">Rider</p>
                </div>
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className={`driver-icon bg-${request?.driver?.name ? "warning" : "danger"}-light me-3`}>
                      <i className={`bi bi-truck text-${request?.driver?.name ? "warning" : "danger"}`}></i>
                    </div>
                    <div>
                      <p className={`m-0 text-${request?.driver?.name ? "warning" : "danger"}`}>{request?.driver?.name || 'Not assigned'}</p>
                      <p className="m-0 small">{request?.driver?.username}</p>
                    </div>
                  </div>

                  <hr />

                  <p className="m-0 text-dark fw-bold lead">Note</p>
                  <p className="m-0 small">{request?.driver_note}</p>
                </div>
              </div>

              <div className="card">
                <div className="card-header bg-white border-bottom-0">
                  <p className="m-0 text-dark fw-bold lead">Request Summary</p>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="text-dark fw-bold">Status</p>
                    <p>{getRequestStatus[request?.status]}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Before Commission</p>
                    <p className="m-0">Rs. {request?.total.toFixed(2)}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0 text-dark fw-bold">Commission</p>
                    <p className="m-0">Rs. {request?.commission.toFixed(2)}</p>
                  </div>
                </div>
                <div className="card-footer border-top-0 d-flex justify-content-between align-items-center">
                  <p className="m-0 text-danger fw-bold lead">Total</p>
                  <p className="m-0 text-danger fw-bold lead">Rs. {(request?.total - request?.commission).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Request
