import moment from "moment"

export const paramsToQuery = (params) => {
  const query = new URLSearchParams()
  for (const key in params) query.append(key, params[key])
  return query.toString()
}

export const getShippingStatus = (ship_datetime) => {
  const date1 = moment()
  const date2 = moment(ship_datetime)
  const difference = date2.diff(date1, "days")

  if (difference < -1) {
    return {
      style: {
        backgroundColor: "#da292e",
        color: "#ffffff",
      },
      status: "Delayed"
    }
  } else if (difference < 0) {
    return {
      style: {
        backgroundColor: "#f8d4d5",
        color: "#000000",
      },
      status: "Yesterday"
    }
  } else if (difference === 0) {
    return {
      style: {
        backgroundColor: "#3459e6",
        color: "#ffffff",
      },
      status: "Today"
    }
  } else if (difference > 1) {
    return {
      style: {
        backgroundColor: "#fdf2df",
        color: "#000000",
      },
      status: "Soon"
    }
  } else if (difference > 0) {
    return {
      style: {
        backgroundColor: "#f4bd61",
        color: "#ffffff",
      },
      status: "Tomorrow"
    }
  }
}

export const getTotal = (array) => {
  
}