import Layout from "../../components/Layout"
import OrderCountValue from "../../components/OrderCountValue"

const ReportOrders = () => {
  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="orders" className="my-2 text-dark">
        <div className="container-fluid">
          <OrderCountValue />
        </div>
      </main>
    </Layout>
  )
}

export default ReportOrders
