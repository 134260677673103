import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as cartSlice from "../../store/cart"
import * as productSlice from "../../store/product"
import * as dealSlice from "../../store/deal"
import * as productApis from "../../utilities/apis/product"
import * as dealApis from "../../utilities/apis/deal"
import { url } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"

const Order = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)
  const productState = useSelector(state => state.product)
  const dealState = useSelector(state => state.deal)

  const [deals, setDeals] = useState(dealState.deals)
  const [products, setProducts] = useState(productState.products)

  const getDeals = async (synced = true) => {
    try {
      if (dealState.deals.length && synced) return

      const query = new URLSearchParams()
      const response = await dealApis.getDeals(token, query.toString())
      if (!response.status) throw new Error(response.message)

      dispatch(dealSlice.pushDeals(response.deals.map((deal) => ({
        ...deal,
        type: "deal",
        cart: 0,
        swap: 0
      }))))
      setDeals(response.deals.map((deal) => ({
        ...deal,
        type: "deal",
        cart: 0,
        swap: 0
      })))
    } catch (error) {
      alert(error.message)
    }
  }

  const getProducts = async (synced = true) => {
    try {
      if (productState.products.length && synced) return

      const query = new URLSearchParams()
      const response = await productApis.getProducts(token, query.toString())
      if (!response.status) throw new Error(response.message)

      dispatch(productSlice.pushProducts(response.products.map((product) => ({
        ...product,
        type: "product",
        cart: 0
      }))))
      setProducts(response.products.map((product) => ({
        ...product,
        type: "product",
        cart: 0
      })))
    } catch (error) {
      alert(error.message)
    }
  }

  const handleIncrement = (type, id) => {
    switch (type) {
      case "deal":
        setDeals((previous) => previous.map((deal) => ({ ...deal, cart: deal.id === id ? deal.cart + 1 : deal.cart })))
        break
      case "product":
        setProducts((previous) => previous.map((product) => ({ ...product, cart: product.id === id ? product.cart + 1 : product.cart })))
        break
    }
  }

  const handleDecrement = (type, id) => {
    switch (type) {
      case "deal":
        setDeals((previous) => previous.map((deal) => ({ ...deal, cart: deal.id === id ? deal.cart - 1 : deal.cart })))
        break
      case "product":
        setProducts((previous) => previous.map((product) => ({ ...product, cart: product.id === id ? product.cart - 1 : product.cart })))
        break
    }
  }

  const handleChange = (e, deal_id, product_id, index) => {
    let swap = window.prompt("Would you like to add swap charges?", 50)
    if (!swap) swap = 0

    const replaceFrom = deals.find((deal) => deal.id === deal_id).products.find((product) => product.id === product_id)
    const replaceTo = products.find((product) => product.id === parseInt(e.target.value))
    const newProduct = {
      id: replaceTo.id,
      previous_id: replaceFrom.id,
      description: replaceTo.description,
      category_id: replaceTo.category_id,
      order_quantity: replaceFrom.order_quantity,
      cost: replaceTo.cost,
      price: replaceTo.price,
      title: replaceTo.title,
      user_id: replaceTo.user_id,
      swap: parseFloat(swap)
    }
    setDeals(previous => previous.map(deal => deal.id === deal_id ? { ...deal, products: deal.products.map((product, i) => i === index ? newProduct : product) } : deal))
  }

  const handleClick = (deal_id, product_id, previous_product_id) => {
    const replaceFrom = deals.find((deal) => deal.id === deal_id).products.find((product) => product.id === product_id)
    const replaceTo = products.find((product) => product.id === previous_product_id)
    const newProduct = {
      id: replaceTo.id,
      description: replaceTo.description,
      category_id: replaceTo.category_id,
      order_quantity: replaceFrom.order_quantity,
      cost: replaceTo.cost,
      price: replaceTo.price,
      title: replaceTo.title,
      user_id: replaceTo.user_id
    }
    setDeals(previous => previous.map(deal => deal.id === deal_id ? { ...deal, products: deal.products.map((product) => product.id === product_id ? newProduct : product) } : deal))
  }

  const handleCheckout = () => {
    const cart = deals.filter((deal) => deal.cart > 0).concat(products.filter((product) => product.cart !== 0))
    if (!cart.length) return alert("Please fill up your cart before saving")

    dispatch(cartSlice.pushProducts(cart))
    navigate("/dashboard/checkout")
  }

  useEffect(() => {
    if (!user) navigate("/")

    getDeals()
    getProducts()
  }, [])

  const cart = deals.filter((deal) => deal.cart > 0).concat(products.filter((product) => product.cart > 0))
  const cartTotal =
    products
      .filter((product) => product.cart > 0)
      .reduce((previous, current) => previous += (current.cart * current.price), 0) +
    deals
      .filter((deal) => deal.cart > 0)
      .map((deal) => ({
        ...deal,
        price: deal.price - deal.discount + deal.products.reduce((previous, current) => previous += current.swap || 0, 0)
      }))
      .reduce((previous, current) => previous += (current.cart * current.price), 0)

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order">
        <section className="flex-grow-3 py-2 px-2">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="fw-bold m-0">Deals</h3>
            <button className="btn btn-sm btn-primary" onClick={() => getDeals(false)}><i className="bi bi-arrow-repeat"></i></button>
          </div>
          <div className="row row-cols-1 row-cols-md-6 g-1">
            {deals.map((deal) => {
              return (
                <div className="col" key={deal.id.toString()}>
                  <div className="card h-100">
                    <div className="position-relative">
                      <img src={deal.thumbnail_url?.replace("public", url) ?? deal.thumbnail} className="card-img-top" alt={deal.title} />
                      <div className="btn-group btn-group-sm d-inline-block ms-auto m-1" role="group">
                        {
                          deal.cart > 0 && <>
                            <button type="button" className="btn btn-danger" onClick={() => handleDecrement("deal", deal.id)}><i className={`bi bi-${deal.cart === 1 ? "x" : "dash"}-lg`}></i></button>
                            <button className="btn btn-primary">{deal.cart}</button>
                          </>
                        }
                        <button type="button" className="btn btn-primary" onClick={() => handleIncrement("deal", deal.id)}><i className="bi bi-plus-lg"></i></button>
                      </div>
                    </div>
                    <div className="card-body p-2">
                      <h6 className="card-title fw-bold text-dark">{deal.title}</h6>
                      <p className="small m-0">Rs. <span className="text-decoration-line-through m-0">{(deal.price).toFixed(2)}</span> ({(deal.sale).toFixed(2)})</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <br />

          <div className="d-flex justify-content-between align-items-center mb-2">
            <h3 className="fw-bold m-0">Products</h3>
            <button className="btn btn-sm btn-primary" onClick={() => getProducts(false)}><i className="bi bi-arrow-repeat"></i></button>
          </div>
          <div className="row row-cols-1 row-cols-md-6 g-1">
            {products.map((product) => {
              return (
                <div className="col" key={product.id.toString()}>
                  <div className="card h-100">
                    <div className="position-relative">
                      <img src={product.thumbnail_url?.replace("public", url) ?? product.thumbnail} className="card-img-top" alt={product.title} />
                      <div className="btn-group btn-group-sm d-inline-block ms-auto m-1" role="group">
                        {
                          product.cart > 0 && <>
                            <button type="button" className="btn btn-danger" onClick={() => handleDecrement("product", product.id)}><i className={`bi bi-${product.cart === 1 ? "x" : "dash"}-lg`}></i></button>
                            <button className="btn btn-primary">{product.cart}</button>
                          </>
                        }
                        <button type="button" className="btn btn-primary" onClick={() => handleIncrement("product", product.id)}><i className="bi bi-plus-lg"></i></button>
                      </div>
                    </div>
                    <div className="card-body p-2">
                      <h6 className="card-title fw-bold text-dark">{product.title}</h6>
                      <p className="small m-0">Rs. {product.price.toFixed(2)}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </section>

        <aside className="flex-grow-1 border-start d-flex flex-column">
          <h3 className="bg-light fw-bold border-bottom m-0 p-2">Cart</h3>
          <div className="items">
            <ul className="list-group list-group-flush shadow-none">
              {cart.map((item, index) => {
                switch (item.type) {
                  case "deal":
                    return (
                      <li className="list-group-item p-2" key={`deal-${index.toString()}`}>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <img src={item.thumbnail_url?.replace("public", url) ?? item.thumbnail} alt={item.title} width="70" height="60" className="me-2 rounded" />
                          <div className="flex-grow-1 ms-2">
                            <p className="m-0">{item.title}</p>
                            <p className="m-0">x{item.cart}=<span className="fw-bold">{(item.cart * item.sale).toFixed(2)}</span></p>
                          </div>
                        </div>
                        {item.products.map((product, index) => <div className="d-flex justify-content-between align-items-center mb-1" key={`product-deal-${index.toString()}`}>
                          <select className="form-select form-select-sm flex-shrink-1 me-2" onChange={(e) => handleChange(e, item.id, product.id, index)} value={product.id}>
                            {products.map((option, index) => <option key={`product-deal-select-${index.toString()}`} value={option.id}>{option.title}</option>)}
                          </select>
                          <span>x{product.order_quantity}</span>
                          {product.swap > 0 && <span className="text-end fw-bold bg-dark text-white ms-2 ps-2 py-1 rounded-start">{[+ product.swap]}</span>}
                          {product.swap > 0 && <span className="text-end fw-bold bg-dark text-danger pe-2 py-1 rounded-end"><i className="fas fa-trash-alt swap-x ms-2" onClick={() => handleClick(item.id, product.id, product.previous_id)}></i></span>}
                        </div>)}
                        <div className="text-end fw-bold text-danger"><i className="fas fa-tag me-2" title="Discount"></i> Rs. {(item.cart * item.discount).toFixed(2)}</div>
                        <div className="text-end fw-bold text-success"><i className="fas fa-exchange-alt me-2" title="Swap"></i> Rs. {(item.cart * item.products.reduce((previous, current) => previous += current.swap || 0, 0)).toFixed(2)}</div>
                        <div className="text-end fw-bold">Rs. {(item.cart * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}</div>
                      </li>
                    )
                  case "product":
                    return (
                      <li className="list-group-item p-2" key={`product-${index.toString()}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <img src={item.thumbnail_url?.replace("public", url) ?? item.thumbnail} alt={item.title} width="70" height="60" className="me-2 rounded" />
                          <div className="flex-grow-1 ms-2">
                            <p className="m-0">{item.title}</p>
                            <p className="m-0">x{item.cart}=<span className="fw-bold">{(item.cart * item.price).toFixed(2)}</span></p>
                          </div>
                        </div>
                      </li>
                    )
                }
              })}
            </ul>
          </div>
          <div className="bg-light p-2 border-top">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h5 className="fw-bold m-0">Total</h5>
              <h5 className="fw-bold m-0">Rs. {cartTotal.toFixed(2)}</h5>
            </div>
            <div className="d-grid">
            {(permissions["CREATE_ORDER"]) && (<button type="submit" className="btn btn-primary" onClick={handleCheckout}>Checkout</button>)}
            </div>
          </div>
        </aside>
      </main>
    </Layout>
  )
}

export default Order
