import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import * as productApis from "../utilities/apis/product"
import * as orderApis from "../utilities/apis/order"
import { url } from "../utilities/enumerations/constants"
import GuestLayout from "../components/GuestLayout"

const Product = () => {
  const navigate = useNavigate()
  const { token } = useSelector((state) => state.user)
  const { slug } = useParams()
  const mobileRef = useRef(null)
  const addressRef = useRef(null)
  const dateRef = useRef(null)
  const timeRef = useRef(null)
  const noteRef = useRef(null)
  const defaultDate = moment().add(2, "days").format("YYYY-MM-DD")
  const defaultTime = "12:40"
  const [product, setProduct] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState({})

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const shipDatetime = moment(`${dateRef.current.value} ${timeRef.current.value}`)
      const lineitems = [{
        cost: product.cost,
        price: product.price,
        quantity: product.quantity,
        product_id: product.id,
      }]
      const payload = {
        zone: {
          name: "N/A"
        },
        customer: {
          mobile: mobileRef.current.value,
          address: addressRef.current.value
        },
        order: {
          discount: product.discount || 0,
          customer_note: noteRef.current.value,
          ship_datetime: shipDatetime.format("YYYY-MM-DD HH:mm"),
          total: product.price,
          cost: product.cost,
          commission: 0,
        },
        order_lineitems: lineitems
      }
      const response = await orderApis.checkout(token, payload)
      if (!response.status) throw new Error(response.message)

      alert("Order created successfully")
      navigate("/")
    } catch (error) {
      alert("Error creating order: " + error.message)
    }
  }

  const getProduct = async () => {
    try {
      const response = await productApis.getHomeProduct(slug)
      if (!response.status) throw new Error(response.message)

      setProduct(response.product)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (slug) getProduct()

    dateRef.current.value = defaultDate
    timeRef.current.value = defaultTime
  }, [slug])

  return (
    <GuestLayout
      header={true}
      footer={true}
    >
      <div className="container py-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="fw-bold m-0">Product Details</h3>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <img src={product?.thumbnail_url?.replace("public", url) ?? product?.thumbnail} alt={product?.title} className="img-fluid rounded" />
                  </div>
                  <div className="col-md-9">
                    <h5>Title</h5>
                    <p>{product?.title}</p>

                    <h5>Description</h5>
                    <p>{product?.description}</p>

                    <h5>Price</h5>
                    <p>Rs. {product?.price.toFixed(2)}</p>
                  </div>
                </div>
              </div>
            </div>
            {product && product.video_url && <div className="card">
              <div className="card-body p-1">
                <iframe src={product.video_url} className="video"></iframe>
              </div>
            </div>}
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-floating mb-2">
                    <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile" required />
                    <label htmlFor="mobile">Mobile No</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input ref={addressRef} type="text" className="form-control" id="address" placeholder="Address" required />
                    <label htmlFor="address">Address</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input ref={dateRef} type="date" className="form-control" id="date" placeholder="Ship Date" required />
                    <label htmlFor="date">Ship Date</label>
                  </div>
                  <div className="form-floating mb-2">
                    <input ref={timeRef} type="time" className="form-control" id="time" placeholder="Ship Time" required />
                    <label htmlFor="time">Ship Time</label>
                  </div>
                  <div className="form-floating mb-2">
                    <textarea ref={noteRef} className="form-control" id="note" placeholder="Note"></textarea>
                    <label htmlFor="note">Note</label>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <h5>Total:</h5>
                    <p>Rs. {product?.price.toFixed(2)}</p>
                  </div>
                  <button type="submit" className="btn btn-primary w-100">Place Order</button>
                </form>
                {product && product.whatsapp_url && <button type="button" className="btn btn-success w-100 mt-2"><a style={{ textDecoration: "none", color: "white" }} href={product.whatsapp_url} target="_blank"><i className="fab fa-whatsapp me-1"></i> Whatsapp</a></button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </GuestLayout>
  )
}

export default Product
