import Layout from "../../components/Layout"
import CustomerCountValue from "../../components/CustomerCountValue"
import ZoneCountValue from "../../components/ZoneCountValue"
import CustomerZoneCount from "../../components/CustomerZoneCount"

const ReportCustomers = () => {
  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="customers" className="my-2 text-dark">
        <div className="container-fluid">
          <CustomerCountValue />
          <ZoneCountValue />
          <CustomerZoneCount />
        </div>
      </main>
    </Layout>
  )
}

export default ReportCustomers
