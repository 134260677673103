import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Layout from "../../components/Layout"
import * as permissionsApis from "../../utilities/apis/permissions"

const Permissions = () => {
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector((state) => state.user)

  const pagePermissions = {
    "Customers": ["CREATE CUSTOMER", "UPDATE CUSTOMER", "DELETE CUSTOMER", "READ CUSTOMER", "READ CUSTOMERS", "CREATE CAMPAIGN", "UPDATE CAMPAIGN", "DELETE CAMPAIGN", "READ CAMPAIGN", "READ CAMPAIGNS"],
    "Products": ["CREATE CATEGORY", "UPDATE CATEGORY", "DELETE CATEGORY", "READ CATEGORY", "READ CATEGORIES", "CREATE PRODUCT", "UPDATE PRODUCT", "DELETE PRODUCT", "READ PRODUCT", "READ PRODUCTS", "CREATE DEAL", "UPDATE DEAL", "DELETE DEAL", "READ DEAL", "READ DEALS", "CREATE PRODUCT DEAL", "UPDATE PRODUCT DEAL", "DELETE PRODUCT DEAL", "READ PRODUCT DEAL", "READ PRODUCT DEALS"],
    "Riders": ["CREATE DRIVER", "UPDATE DRIVER", "DELETE DRIVER", "READ DRIVER", "READ DRIVERS"],
    "Orders": ["CREATE ORDER", "DELETE ORDER", "READ ORDER", "READ ORDERS", "PAY ORDER", "TRASH ORDER", "RESTORE ORDER", "RECOVER ORDER", "UPDATE DRIVER NOTE", "CREATE REQUEST", "UPDATE REQUEST", "DELETE REQUEST", "READ REQUEST", "READ REQUESTS", "COMPLETE REQUEST", "COMPLETE REQUESTS", "CREATE ORDER REQUEST", "DELETE ORDER REQUEST", "READ ORDER REQUEST", "READ ORDER REQUESTS", "COMPLETE ORDER REQUEST", "COMPLETE ORDER REQUESTS", "REJECT ORDER REQUEST", "REJECT ORDER REQUESTS"],
    "Reports": ["GET CUSTOMER COUNT VALUE", "GET ZONE COUNT VALUE", "GET CUSTOMER ZONE COUNT", "GET ORDER COUNT VALUE", "GET PRODUCTS", "GET PRODUCT BY", "GET SUMMARY", "GET ORDERS", "GET ORDER CUSTOMER RATIO", "GET ORDERS BY ZONE", "GET ORDERS BY DRIVER", "GET ORDERS BY ACTIVE DRIVERS", "GET RIDER COUNT VALUE", "GET INCOME STATEMENT", "CREATE INCOME STATEMENT", "UPDATE INCOME STATEMENT", "DELETE INCOME STATEMENT", "DELETE INCOME STATEMENTS", "READ INCOME STATEMENT", "READ INCOME STATEMENTS"],
    "Roles": ["UPDATE ROLE", "READ ROLE", "READ ROLES"],
    "Users": ["CREATE USER", "UPDATE USER", "DELETE USER", "READ USER", "READ USERS"],
    "Zones": ["CREATE ZONE", "UPDATE ZONE", "DELETE ZONE", "READ ZONE", "READ ZONES"],
    "Insights": ["READ INSIGHTS"],
  }

  const [selectedRole, setSelectedRole] = useState({})
  const [selectedRoleId, setSelectedRoleId] = useState(null)
  const [selectedPage, setSelectedPage] = useState("Customers")
  const [selectedPermissions, setSelectedPermissions] = useState({})
  const [roles, setRoles] = useState([])

  const handlePageChange = (page) => {
    setSelectedPage(page)
  }

  const handleRoleChange = async (e) => {
    const roleId = e.target.value
    setSelectedRoleId(roleId)
    setSelectedRole(roles.find((role) => role.id === roleId))
  }


  const handleCheckboxChange = (permission) => {
    setSelectedPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permission]: !prevPermissions[permission],
    }))
  }

  const handleSave = async () => {
    try {
      const allPermissions = {
        id: selectedRoleId,
        permissions: selectedPermissions
      }

      const response = await permissionsApis.updateRole(token, allPermissions)
      if (!response.status) throw new Error(response.message)

      alert("Permissions saved")
    } catch (error) {
      alert(error.message)
    }
  }

  const getRoles = async () => {
    try {
      const response = await permissionsApis.getRoles(token, selectedRole)
      if (!response.status) throw new Error(response.message)

      setSelectedRole(response.roles[0].name)
      setSelectedRoleId(response.roles[0].id)
      setRoles(response.roles)
    } catch (error) {
      alert(error.message)
    }
  }

  const getRole = async () => {
    try {
      if (!selectedRoleId) return

      const response = await permissionsApis.getRole(token, selectedRoleId)
      if (!response.status) throw new Error(response.message)

      setSelectedPermissions(JSON.parse(response.role.permissions))
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")
    getRoles()
  }, [])

  useEffect(() => {
    getRole()
  }, [selectedRoleId])

  return (
    <Layout header={true} footer={true}>
      <main id="permissions">
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-md-3">
              <h3>Roles</h3>
              <select
                value={selectedRole}
                onChange={handleRoleChange}
                className="form-select"
                style={{
                  textTransform: "capitalize",
                }}
              >
                {roles.map((role) => (
                  <option key={role.id.toString()} value={role.id}>{role.name}</option>
                ))}
              </select>

              <br />

              <h3>Features</h3>
              <ul className="list-group shadow-none">
                {Object.keys(pagePermissions).map((page) => (
                  <li
                    className={`list-group-item list-group-item-action ${selectedPage === page && "active"}`}
                    key={page}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-9">
              <h3>Permissions for {selectedPage}</h3>

              <div className="row g-2 my-3">
                {pagePermissions[selectedPage].map((permission) => (
                  <div className="col-3" key={permission}>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id={permission} checked={selectedPermissions[permission] === true} onChange={() => handleCheckboxChange(permission)} />
                      <label className="form-check-label" for={permission}>{permission}</label>
                    </div>
                  </div>
                )
                )}
              </div>
              {(permissions["UPDATE_ROLE"]) && (<button className="btn btn-success w-25" onClick={handleSave}>Save</button>)}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Permissions