import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import * as reportService from "../utilities/services/report"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  scales: {
    xAxes: [{
      ticks: {
        steps: 10,
      }
    }],
  },
}

const CustomerCountValue = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const [customers, setCustomers] = useState([])
  const [limit, setLimit] = useState("20")
  const [report, setReport] = useState("count")

  const getCustomerCountValue = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getCustomerCountValue(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setCustomers(response.customers)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getCustomerCountValue({
      limit,
      orderBy: report,
    })
  }, [])

  useEffect(() => {
    getCustomerCountValue({
      limit,
      orderBy: report,
    })
  }, [limit])

  useEffect(() => {
    getCustomerCountValue({
      limit,
      orderBy: report,
    })
  }, [report])

  const data = {
    labels: customers.map(x => x.mobile),
    datasets: [{
      label: "Customers",
      data: customers.map(x => parseFloat(x[report])),
      backgroundColor: "#d5f0e6",
    }]
  }

  return (
    <div className="card mb-4">
      <div className="card-header bg-white">
        <div className="row align-items-center">
          <div className="col-md-3 fw-bold">Customer <span className="text-capitalize">{report}</span></div>
          <div className="col-md-5"></div>
          <div className="col-md-4 d-flex justify-content-end">
            <div className="btn-group btn-group-sm ms-2" role="group">
              <button type="button" className={"btn btn-light " + (limit === "5" ? "active" : "")} onClick={() => setLimit("5")}>5</button>
              <button type="button" className={"btn btn-light " + (limit === "10" ? "active" : "")} onClick={() => setLimit("10")}>10</button>
              <button type="button" className={"btn btn-light " + (limit === "20" ? "active" : "")} onClick={() => setLimit("20")}>20</button>
            </div>
            <div className="btn-group btn-group-sm ms-2" role="group">
              <button type="button" className={"btn btn-light " + (report === "value" ? "active" : "")} onClick={() => setReport("value")}>Value</button>
              <button type="button" className={"btn btn-light " + (report === "count" ? "active" : "")} onClick={() => setReport("count")}>Count</button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        {customers.length > 0 && <Bar options={options} data={data} height={75} />}
      </div>
    </div>
  )
}

export default CustomerCountValue
