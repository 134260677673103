import axios from "axios"
import { apiUrl } from "../enumerations/constants"

export const createCampaign = async (token, payload) => {
  const { data: response } = await axios.post(`${apiUrl}/campaign`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const updateCampaign = async (token, payload) => {
  const { data: response } = await axios.put(`${apiUrl}/campaign`, payload, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const deleteCampaign = async (token, id) => {
  const { data: response } = await axios.delete(`${apiUrl}/campaign/${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCampaigns = async (token, query) => {
  const { data: response } = await axios.get(`${apiUrl}/campaigns?${query}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const getCampaign = async (token, id) => {
  const { data: response } = await axios.get(`${apiUrl}/campaign?id=${id}`, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const sentCampaign = async (token, id) => {
  const { data: response } = await axios.patch(`${apiUrl}/campaign/sent`, { id }, {
    headers: {
      "Token": token
    }
  })
  return response
}

export const respondedCampaign = async (token, id) => {
  const { data: response } = await axios.patch(`${apiUrl}/campaign/responded`, { id }, {
    headers: {
      "Token": token
    }
  })
  return response
}