import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as productApis from "../utilities/apis/product"

const HomeProducts = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const [products, setProducts] = useState([])

  const getProducts = async () => {
    try {
      const response = await productApis.getHomeProducts(token, "")
      if (!response.status) throw new Error(response.message)
    
      setProducts(response.products)
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getProducts()
  }, [])

  const handleView = (slug) => {
    navigate(`/product/${slug}`)
  }

  return (
    products.length && <section className="bg-light py-5">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h3 className="display-1 fw-bold mb-3" id="products">Products</h3>
        </div>

        <div className="row g-1">
          {products.map((product) => (
            <div key={product.id.toString()} className="col-lg-2 col-md-6">
              <div className="card">
                <img src={product.thumbnail} className="card-img-top img-fluid deal-image" alt={product.title} />
                <div className="card-body p-2">
                  <h5 className="text-dark fw-bold m-0 text-truncate">{product.title}</h5>
                  <p className="text-primary fw-bold mt-3 m-0">Rs. {(product.price).toFixed(2)}</p>
                </div>
                <div className="card-footer p-0">
                  <button className="btn btn-sm btn-link w-100" onClick={() => handleView(product.slug)}>View</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default HomeProducts