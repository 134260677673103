import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as driverApis from "../utilities/apis/driver"
import * as reportService from "../utilities/services/report"

const DriverBy = () => {
  const navigate = useNavigate()
  const { token, user } = useSelector(state => state.user)
  const [drivers, setDrivers] = useState([])
  const [orders, setOrders] = useState([])
  const [earningsBy, setEarningsBy] = useState("Mon")
  const [driver, setDriver] = useState("")
  const [toggleMonth, setToggleMonth] = useState(true)
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")
  const currentYear = new Date().getFullYear()

  const by = {
    Mon: "Month",
    YYYY: "Year"
  }

  const order = {
    Mon: "MM",
    YYYY: "YYYY"
  }

  const getDrivers = async () => {
    try {
      const response = await driverApis.getDrivers(token, {})
      if (!response.status) throw new Error(response.message)

      const activeDriver = response.drivers.filter(driver => driver.active === true)
      setDrivers(activeDriver)
    } catch (error) {
      alert(error.message)
    }
  }

  const getEarningsByRider = async (params) => {
    try {
      const query = new URLSearchParams(params)
      const response = await reportService.getEarningsByRider(token, query.toString())
      if (!response.status) throw new Error(response.message)

      setOrders(response.orders)
    } catch (error) {
      setOrders([])
    }
  }

  const handleDriver = (e) => {
    setDriver(e.target.value)
  }

  const handleOrdersBy = (orderBy) => {
    setToggleMonth(orderBy === "Mon")
    setEarningsBy(orderBy)
  }

  const handleMonth = (e) => {
    setMonth(e.target.value)
  }

  const handleYear = (e) => {
    setYear(e.target.value)
  }

  useEffect(() => {
    if (!user) navigate("/")

    getDrivers()
  }, [])

  useEffect(() => {
    const params = {
      by: earningsBy,
      order: order[earningsBy],
    }

    if (month) params.month = month
    if (year) params.year = year
    if (driver) {
      params.driver_id = driver
      getEarningsByRider(params)
    }
  }, [driver, month, year, earningsBy])

  return (
    <div className="col-12">
      <div className="card h-100 ">
        <div className="col-md-12 p-2 card-header">
          <div className="row align-items-center">
            <div className="col-md-4 fw-bold">Rider Earnings by <span className="text-capitalize">{by[earningsBy]}</span></div>
            <div className="col-md-8 d-flex justify-content-end gap-2">
              <select onChange={handleDriver} className="form-select">
                <option value="">Select Rider</option>
                {drivers.map((driver) => (
                  <option key={driver.id.toString()} value={driver.id}>{driver.username} - {driver.name}</option>
                ))}
              </select>
              {toggleMonth && <select className="form-select form-select-sm" onChange={handleMonth}>
                <option value="">All</option>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>}
              <select className="form-select form-select-sm" onChange={handleYear}>
                <option value="">All</option>
                {Array.from({ length: currentYear - 2022 }, (_, index) => {
                  const yearValue = currentYear - index
                  return (
                    <option key={yearValue} value={yearValue}>{yearValue}</option>
                  )
                })}
              </select>
              <div className="btn-group btn-group-sm" role="group">
                <button type="button" className={"btn btn-light " + (earningsBy === "Mon" ? "active" : "")} onClick={() => handleOrdersBy("Mon")}>Month</button>
                <button type="button" className={"btn btn-light " + (earningsBy === "YYYY" ? "active" : "")} onClick={() => handleOrdersBy("YYYY")}>Year</button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <table className="table table-sm table-hover shadow-none text-dark mb-0">
            <thead className="table-light">
              <tr>
                <th scope="col" style={{ width: "40%" }} className="align-middle">Month</th>
                <th scope="col" style={{ width: "40%" }} className="align-middle">Amount</th>
                <th scope="col" style={{ width: "40%" }} className="align-middle">Earned</th>
                <th scope="col" style={{ width: "20%" }} className="align-middle text-center">Orders</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td className="align-middle">{order.by}</td>
                  <td className="align-middle">Rs. {order.value}</td>
                  <td className="align-middle">Rs. {order.commission.toFixed(2)}</td>
                  <td className="align-middle lead fw-bold">{order.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default DriverBy