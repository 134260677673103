import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import * as customerApis from "../../utilities/apis/customer"
import * as zoneApis from "../../utilities/apis/zone"
import * as orderApis from "../../utilities/apis/order"
import { isDeal, isProduct, url } from "../../utilities/enumerations/constants"
import * as functions from "../../utilities/enumerations/functions"
import Layout from "../../components/Layout"

const Checkout = () => {
  const navigate = useNavigate()
  const { user, token } = useSelector(state => state.user)
  const { products: cart } = useSelector(state => state.cart)
  const mobileRef = useRef(null)
  const zoneRef = useRef(null)
  const addressRef = useRef(null)
  const dateRef = useRef(null)
  const timeRef = useRef(null)
  const noteRef = useRef(null)
  const discountRef = useRef(null)
  const totalRef = useRef(null)
  const [discount, setDiscount] = useState(0)
  const [customers, setCustomers] = useState([])
  const [zones, setZones] = useState([])
  const [active, setActive] = useState(-1)
  const [activeZone, setActiveZone] = useState(-1)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    try {
      setLoading(true)
      e.preventDefault()

      const ship_datetime = moment(`${dateRef.current.value} ${timeRef.current.value}`)
      const products = cart.filter((product) => isProduct(product.type)).map((product) => ({
        cost: product.cost,
        price: product.price,
        quantity: product.cart,
        product_id: product.id
      }))
      const orderDeals = cart.filter((deal) => isDeal(deal.type))
      const deals = []

      orderDeals.forEach((deal) => deal.products.map((product) => {
        deals.push({
          cost: product.cost,
          price: product.price,
          quantity: product.order_quantity,
          deal_cost: deal.cost,
          deal_price: deal.price,
          deal_discount: deal.discount,
          deal_quantity: deal.cart || 0,
          swap: product.swap || 0,
          product_id: product.id,
          deal_id: deal.id
        })
      }))
      const lineitems = products.concat(deals)

      const payload = {
        zone: {
          name: zoneRef.current.value
        },
        customer: {
          mobile: mobileRef.current.value,
          address: addressRef.current.value
        },
        order: {
          discount: parseFloat(discountRef.current.value),
          customer_note: noteRef.current.value,
          ship_datetime: ship_datetime.format("YYYY-MM-DD HH:mm"),
          total: parseFloat(totalRef.current.value) + discount,
          cost: cart.reduce((previous, current) => previous += (current.cart * current.cost), 0),
          commission: 0
        },
        order_lineitems: lineitems
      }

      const response = await orderApis.createOrder(token, payload)
      if (!response.status) throw new Error(response.message)

      alert("Order punched")
      navigate("/dashboard")
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  const goCDown = () => {
    setActive((previous) => {
      if (previous >= customers.length)
        previous = 0
      else
        previous += 1

      mobileRef.current.value = customers[previous]?.mobile || ""
      return previous
    })
  }

  const goCUp = () => {
    setActive((previous) => {
      if (previous <= 0)
        previous = customers.length
      else
        previous -= 1

      mobileRef.current.value = customers[previous]?.mobile || ""
      return previous
    })
  }

  const handleMobile = async (e) => {
    try {
      const mobile = e.target.value
      if (mobile.length < 3) throw new Error("a")

      const response = await customerApis.getCustomers(token, functions.paramsToQuery({ mobile }))
      if (!response.status) throw new Error(response.message)
      if (response.customers && response.customers.length === 0) throw new Error(response.message)

      setCustomers(response.customers)
    } catch (error) {
      addressRef.current.value = ""
      setCustomers([])
    }
  }

  const handleMobileKeyup = (e) => {
    if (e.which === 40) goCDown()
    if (e.which === 38) goCUp()
  }

  const handleMobileBlur = (e) => {
    const customer = customers.find((customer) => customer.mobile === e.target.value)
    if (!customer) {
      setTimeout(() => {
        setActive(-1)
        setCustomers([])
      }, 100)
      return
    }

    mobileRef.current.value = customer.mobile
    addressRef.current.value = customer.address

    setTimeout(() => {
      setActive(-1)
      setCustomers([])
    }, 100)
  }

  const handleMobileSelect = (value) => {
    const customer = customers.find((customer) => customer.mobile === value)
    if (!customer) return

    mobileRef.current.value = customer.mobile
    addressRef.current.value = customer.address
    setCustomers([])
  }

  const goZDown = () => {
    setActiveZone((previous) => {
      if (previous >= zones.length)
        previous = 0
      else
        previous += 1

      zoneRef.current.value = zones[previous]?.name || ""
      return previous
    })
  }

  const goZUp = () => {
    setActiveZone((previous) => {
      if (previous <= 0)
        previous = zones.length
      else
        previous -= 1

      zoneRef.current.value = zones[previous]?.name || ""
      return previous
    })
  }

  const handleZone = async (e) => {
    try {
      const name = e.target.value
      if (name.length < 1) throw new Error("a")

      const response = await zoneApis.getZones(token, functions.paramsToQuery({ name }))
      if (!response.status) throw new Error(response.message)
      if (response.zones && response.zones.length === 0) throw new Error(response.message)

      setZones(response.zones)
    } catch (error) {
      setZones([])
    }
  }

  const handleZoneKeyup = (e) => {
    if (e.which === 40) goZDown()
    if (e.which === 38) goZUp()
  }

  const handleZoneBlur = (e) => {
    const zone = zones.find((zone) => zone.name === e.target.value)
    if (!zone) {
      setTimeout(() => {
        setActiveZone(-1)
        setZones([])
      }, 100)
      return
    }

    zoneRef.current.value = zone.name

    setTimeout(() => {
      setActiveZone(-1)
      setZones([])
    }, 100)
  }

  const handleZoneSelect = (value) => {
    const zone = zones.find((zone) => zone.name === value)
    if (!zone) return

    zoneRef.current.value = zone.name
    setZones([])
  }

  const handleDiscount = (e) => {
    setDiscount(parseFloat(e.target.value || "0"))
  }

  useEffect(() => {
    if (!user) navigate("/")

    const customer = JSON.parse(sessionStorage.getItem("customer"))
    if (customer) {
      mobileRef.current.value = customer.mobile
      addressRef.current.value = customer.address
      zoneRef.current.value = customer.zone
    }

    dateRef.current.defaultValue = moment().format("YYYY-MM-DD")
    timeRef.current.defaultValue = moment().format("HH:mm")
  }, [])

  const cartSwap = cart.filter((product) => isDeal(product.type)).reduce((previous, current) => previous += current.cart * current.products.reduce((previous, current) => previous += current.swap || 0, 0), 0)
  const cartTotal = cart.reduce((previous, current) => {
    if (isDeal(current.type))
      return previous += (current.cart * current.sale) + current.cart * current.products.reduce((previous, current) => previous += current.swap || 0, 0)
    if (isProduct(current.type))
      return previous += (current.cart * current.price)
  }, 0)

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order">
        <form method="POST" onSubmit={handleSubmit}>
          <section className="flex-grow-3 border-end p-3">
            <div className="card mb-2">
              <div className="card-header">
                <p className="fw-bold m-0">Customer</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className={`form-floating mb-3 ${customers.length > 0 ? "show" : "hide"}`}>
                      <input ref={mobileRef} type="text" className="form-control" id="mobile" placeholder="Mobile #" onChange={handleMobile} onKeyUp={handleMobileKeyup} onBlur={handleMobileBlur} required />
                      <label htmlFor="mobile">Mobile #</label>

                      <ul className={`autocomplete ${customers.length > 0 ? "show" : "hide"}`}>
                        {customers.map((customer, index) => (
                          <li key={`customer-${index.toString()}`} className={active === index ? "active" : ""} onClick={() => handleMobileSelect(customer.mobile)}>{customer.mobile}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className={`form-floating mb-3 ${zones.length > 0 ? "show" : "hide"}`}>
                      <input ref={zoneRef} type="text" className="form-control" id="zone" placeholder="Zone" onChange={handleZone} onKeyUp={handleZoneKeyup} onBlur={handleZoneBlur} required />
                      <label htmlFor="zone">Zone</label>

                      <ul className={`autocomplete ${zones.length > 0 ? "show" : "hide"}`}>
                        {zones.map((zone, index) => (
                          <li key={`zone-${index.toString()}`} className={activeZone === index ? "active" : ""} onClick={() => handleZoneSelect(zone.name)}>{zone.name}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="form-floating">
                  <input ref={addressRef} type="text" className="form-control" id="address" placeholder="Address" required />
                  <label htmlFor="address">Address</label>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <p className="fw-bold m-0">Order</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className={`form-floating mb-3 ${customers.length > 0 ? "show" : "hide"}`}>
                      <input ref={dateRef} type="date" className="form-control" id="date" placeholder="Ship Date" required />
                      <label htmlFor="date">Ship Date</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-floating mb-3">
                      <input ref={timeRef} type="time" className="form-control" id="time" placeholder="Ship Time" required />
                      <label htmlFor="time">Ship Time</label>
                    </div>
                  </div>
                </div>

                <div className="form-floating mb-3">
                  <input ref={noteRef} type="text" className="form-control" id="note" placeholder="Note" />
                  <label htmlFor="note">Note</label>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn btn-primary w-25 py-3" disabled={loading}>Confirm</button>
                </div>
              </div>
            </div>
          </section>
          <aside className="flex-grow-1 border-start d-flex flex-column">
            <h3 className="bg-light fw-bold border-bottom m-0 p-2">Items</h3>
            <ul className="flex-grow-1 overflow-y-auto list-group list-group-flush shadow-none">
              {cart.map((item, index) => {
                switch (item.type) {
                  case "deal":
                    return (
                      <li className="list-group-item" key={`deal-${index.toString()}`}>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <img src={item.thumbnail_url?.replace("public", url) ?? item.thumbnail} alt={item.title} width="70" height="60" className="me-2 rounded" />
                          <div className="flex-grow-1 ms-2">
                            <p className="small m-0">{item.title}</p>
                            <p className="small m-0">x{item.cart}=<span className="fw-bold">{(item.cart * item.sale).toFixed(2)}</span></p>
                          </div>
                        </div>
                        {item.products && item.products.map((product, index) => (
                          <div className="d-flex justify-content-between align-items-center mb-1" key={`product-deal-${index.toString()}`}>
                            <p className="small m-0">{product.title}</p>
                            <p className="small m-0">x{product.order_quantity}={product.price}{product.swap ? ` + ${product.swap}` : ""}</p>
                          </div>
                        ))}
                        <div className="text-end fw-bold text-danger"><i className="fas fa-tag me-2" title="Discount"></i> Rs. {(item.cart * item.discount).toFixed(2)}</div>
                        <div className="text-end fw-bold text-success"><i className="fas fa-exchange-alt me-2" title="Swap"></i> Rs. {(item.cart * item.products.reduce((previous, current) => previous += current.swap || 0, 0)).toFixed(2)}</div>
                        <div className="text-end fw-bold">Rs. {(item.cart * (item.sale + item.products.reduce((previous, current) => previous += current.swap || 0, 0))).toFixed(2)}</div>
                      </li>
                    )
                  case "product":
                    return (
                      <li className="list-group-item" key={`product-${index.toString()}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <img src={item.thumbnail_url?.replace("public", url) ?? item.thumbnail} alt={item.title} width="70" height="60" className="me-2 rounded" />
                          <div className="flex-grow-1 ms-2">
                            <p className="m-0">{item.title}</p>
                            <p className="m-0">x{item.cart}=<span className="fw-bold">{(item.cart * item.price).toFixed(2)}</span></p>
                          </div>
                        </div>
                      </li>
                    )
                }
              })}
            </ul>
            <div className="bg-light border-top p-2">
              <div className="row justify-content-between align-items-center g-0 mb-1">
                <div className="col-6 fw-bold">Discount</div>
                <div className="col-6">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text">Rs.</span>
                    <input ref={discountRef} type="number" className="form-control text-end" id="discount" onKeyUp={handleDiscount} onChange={handleDiscount} defaultValue={discount.toFixed(2)} required />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between align-items-center g-0 mb-1">
                <div className="col-6 fw-bold">Swap</div>
                <div className="col-6">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text">Rs.</span>
                    <input type="number" className="form-control text-end" id="swap" disabled required value={cartSwap.toFixed(2)} />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between align-items-center g-0 mb-1">
                <div className="col-6 fw-bold">Total</div>
                <div className="col-6">
                  <div className="input-group input-group-sm">
                    <span className="input-group-text">Rs.</span>
                    <input ref={totalRef} type="number" className="form-control text-end" id="total" disabled value={(cartTotal - discount).toFixed(2)} />
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </form>
      </main>
    </Layout>
  )
}

export default Checkout
