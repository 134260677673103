import CreateCampaign from "../../components/CreateCampaign"
import ReadCampaign from "../../components/ReadCampaign"

const Campaign = () => {
  const params = new URLSearchParams(window.location.search)

  if (params.has("id"))
    return <ReadCampaign />
  else
    return <CreateCampaign />
}

export default Campaign
