import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as orderApis from "../../utilities/apis/order"
import { isDeal, isProduct } from "../../utilities/enumerations/constants"
import Layout from "../../components/Layout"

const EditOrder = () => {
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)
  const { products } = useSelector(state => state.product)
  const params = new URLSearchParams(window.location.search)
  const [order, setOrder] = useState(null)
  const nameRef = useRef(null)
  const mobileRef = useRef(null)
  const addressRef = useRef(null)
  const zoneRef = useRef(null)
  const [selectedProducts, setSelectedProducts] = useState({})

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const products = order.order_lineitems.filter((product) => isProduct(product.type)).map((product) => ({
        cost: product.cost,
        price: product.price,
        quantity: product.quantity,
        product_id: product.id
      }))
      const orderDeals = order.order_lineitems.filter((deal) => isDeal(deal.type))
      const deals = []

      orderDeals.forEach((deal) => deal.products.map((product) => {
        deals.push({
          cost: product.cost,
          price: product.price,
          quantity: product.order_quantity,
          deal_cost: deal.cost,
          deal_price: deal.price,
          deal_discount: deal.discount,
          deal_quantity: deal.quantity,
          swap: product.swap || 0,
          product_id: product.id,
          deal_id: deal.id
        })
      }))
      const lineitems = products.concat(deals)

      const cartTotal = lineitems.reduce((previous, current) => {
        if (isDeal(current.type))
          return previous += (current.cart * current.sale) + current.cart * current.products.reduce((previous, current) => previous += current.swap || 0, 0)
        if (isProduct(current.type))
          return previous += (current.cart * current.price)
      }, 0)

      const payload = {
        zone: {
          name: zoneRef.current.value
        },
        customer: {
          name: nameRef.current.value,
          mobile: mobileRef.current.value,
          address: addressRef.current.value
        },
        order: {
          id: order.id,
          total: cartTotal
        },
        order_lineitems: lineitems
      }

      const response = await orderApis.updateOrder(token, payload)
      if (!response.status) throw new Error(response.message)

      alert("Order updated")
    } catch (error) {
      alert(error.message)
    }
  }

  const handleChange = (e, itemId, productId, productIndex) => {
    const updatedValue = e.target.value
    const selectedProduct = products.find((product) => product.id === parseInt(updatedValue))

    setOrder((prevOrder) => ({
      ...prevOrder,
      order_lineitems: prevOrder.order_lineitems.map((item) => {
        if (item.id === itemId && isDeal(item.type)) {
          return {
            ...item,
            products: item.products.map((product, index) => {
              if (product.id === productId && index === productIndex) {
                return selectedProduct
                  ? { ...product, id: selectedProduct.id, title: selectedProduct.title, price: selectedProduct.price }
                  : null
              }
              return product
            })
          }
        }
        return item
      })
    }))

    setSelectedProducts((prevSelected) => ({
      ...prevSelected,
      [itemId]: {
        ...prevSelected[itemId],
        [productIndex]: selectedProduct
          ? { id: selectedProduct.id, title: selectedProduct.title, price: selectedProduct.price }
          : null,
      },
    }))
  }

  const handleDelete = (itemId, itemType) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      order_lineitems: prevOrder.order_lineitems.filter((item) => (item.id !== itemId && item.type === itemType))
    }))
  }

  const getOrder = async () => {
    try {
      const response = await orderApis.getOrder(token, params.get("id"))
      if (!response.status) throw new Error(response.message)

      setOrder(response.order)
      nameRef.current.value = response.order.customer.name
      mobileRef.current.value = response.order.customer.mobile
      addressRef.current.value = response.order.customer.address
      zoneRef.current.value = response.order.zone.name
    } catch (error) {
      alert(error.message)
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    if (params.has("id"))
      getOrder()
  }, [])

  return (
    <Layout
      header={true}
      footer={true}
    >
      <main id="order" className="my-4">
        <div className="container">
          <h3 className="fw-bold m-0">Order #: <span className="text-danger fw-bold">P{order?.id}</span></h3>
          <div className="card">
            <div className="card-header bg-white border-bottom-0">
              <p className="m-0 text-dark fw-bold lead">Customer</p>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input ref={nameRef} type="text" className="form-control" id="name" placeholder="Name" />
                    <label htmlFor="name">Name</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input ref={mobileRef} type="number" className="form-control" id="mobile" placeholder="Mobile" />
                    <label htmlFor="mobile">Mobile</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input ref={addressRef} type="text" className="form-control" id="addres" placeholder="Address" required />
                    <label htmlFor="address">Address</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input ref={zoneRef} type="text" className="form-control" id="zone" placeholder="Zone" required />
                    <label htmlFor="zone">Zone</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="card mb-4">
            <div className="card-body p-0">
              <table className="table shadow-none mb-0">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "10%" }} className="fw-bold">Type</th>
                    <th scope="col" style={{ width: "20%" }} className="fw-bold">Item</th>
                    <th scope="col" style={{ width: "50%" }} className="fw-bold">Product(s)</th>
                    <th scope="col" style={{ width: "10%" }} className="fw-bold text-center">Quantity</th>
                    <th scope="col" style={{ width: "10%" }} className="fw-bold text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.order_lineitems.map((item) => {
                    return (
                      <tr key={item.id.toString()}>
                        <td className="align-middle text-capitalize">{item.type}</td>
                        <td className="align-middle">{item.title}</td>
                        <td className="align-middle">
                          {isDeal(item.type) && (item.products.map((product, index) => (
                            <select
                              className="form-select form-select-sm mb-1"
                              onChange={(e) => handleChange(e, item.id, product.id, index)}
                              value={selectedProducts[item.id]?.[index]?.id || product.id}
                            >
                              {products.map((option, index) => (
                                <option key={`product-deal-select-${index.toString()}`} value={option.id}>{option.title}</option>
                              ))}
                            </select>
                          )))}
                        </td>
                        <td className="align-middle text-center">{item.quantity}</td>
                        <td className="align-middle text-center">
                          <a className="text-danger" type="button" onClick={() => handleDelete(item.id, item.type)}><i className="far fa-trash-alt"></i></a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="card-footer text-center">
              <button type="button" className="btn btn-primary py-3 w-25" onClick={handleSubmit}>Save</button>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default EditOrder