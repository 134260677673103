import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as userApis from "../../utilities/apis/user"
import Layout from "../../components/Layout"

const Users = () => {
  const navigate = useNavigate()
  const { user, token, permissions } = useSelector(state => state.user)
  const [users, setUsers] = useState([])

  const handleNew = () => {
    navigate("/dashboard/user")
  }

  const handleEdit = (id) => {
    navigate(`/dashboard/user?id=${id}`)
  }

  const handleDelete = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete?")) return

      const response = await userApis.deleteUser(token, id)
      if (!response.status) throw new Error(response.message)

      setUsers((previous) => {
        const users = previous.filter(x => x.id !== id)
        return [...users]
      })
    } catch (error) {
    }
  }

  const getUsers = async () => {
    try {
      const response = await userApis.getUsers(token, "")
      if (!response.status) throw new Error(response.message)

      setUsers(response.users)
    } catch (error) {
    }
  }

  useEffect(() => {
    if (!user) navigate("/")

    getUsers()
  }, [])

  return (
    <Layout
    header={true}
    footer={true}
  >
    <main id="drivers" className="my-2">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h3 className="fw-bold m-0">Users</h3>
          {(permissions["CREATE_USER"]) && (<button className="btn btn-primary" onClick={() => handleNew()}>New</button>)}
        </div>
      </div>

      <table className="table table-bordered table-hover shadow-none">
        <thead className="table-light">
          <tr>
            <th scope="col" style={{ width: "10%" }} className="text-center">ID</th>
            <th scope="col" style={{ width: "40%" }}>Name</th>
            <th scope="col" style={{ width: "10%" }} className="text-center">Role</th>
            <th scope="col" style={{ width: "10%" }} className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((row) => (
            <tr key={row.id.toString()}>
              <td className="align-middle text-center">{row.id}</td>
              <td className="align-middle">{row.name}</td>
              <td className="align-middle text-center">{row.role.name.charAt(0).toUpperCase() + row.role.name.slice(1)}</td>  
              <td className="align-middle text-center">
              {(permissions["UPDATE_USER"]) && (<a className="me-2" onClick={() => handleEdit(row.id)}><i className="far fa-edit"></i></a>)}
              {(permissions["DELETE_USER"]) && (<a className="text-danger" onClick={() => handleDelete(row.id)}><i className="far fa-trash-alt"></i></a>)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  </Layout>
)
}

export default Users
